import React from 'react'
import BrightcoveVideoPlayer from './BrightcoveVideoPlayer'
import VideoJS from './VideojsPlayer'

/*
 * This is a wrapper component for the video players. In Atom we have support for two video players, Brightcove and VideoJS.
 * If you provide the videoId as atom.* then it will be considered as a VideoJS video otherwise it will be considered as a Brightcove video.
 * At this point Brightcove videos should not be used but we are keeping the support for it for backward compatibility.
 */
export default function VideoPlayer(options) {
  const { videoId, quality } = options
  const isBCVideo = videoId?.toString().slice(0, 5) !== 'atom.'

  /* eslint-disable react/jsx-props-no-spreading */
  if (isBCVideo) return <BrightcoveVideoPlayer {...options} />
  return <VideoJS {...options} quality={quality} />
  /* eslint-enable react/jsx-props-no-spreading */
}
