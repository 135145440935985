import React, { useState, useEffect } from 'react'

import Icon from '../../components/elements/Icon'
import Button from '../../components/elements/Button'

import { setBackgroundImage, KIT_MAP } from '../../Helpers'

import kits from '../../data/kits.json'

const styles = {
  width: '100%',
}

type Props = {
  onNext: Object => null,
  onBack: Object => null,
  score: [number],
  gender: string,
}

/**
 * Component for displaying kit recommendations based on user assessment.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {function} props.onBack - Callback function to handle the back button click.
 * @param {function} props.onNext - Callback function to handle the next button click.
 * @param {Array} props.score - Array containing the assessment score and kit score.
 * @param {number} props.score[0] - The assessment score.
 * @param {number} props.score[1] - The kit score.
 * @param {string} props.gender - The gender of the user, either 'male' or 'female'.
 *
 * @example
 * <KitRecommendation
 *   onBack={handleBack}
 *   onNext={handleNext}
 *   score={[3, 2]}
 *   gender="male"
 * />
 */
function KitRecommendation(props: Props) {
  const [section] = useState(0)
  const { onBack, onNext, score, gender } = props
  const [assessmentScore, kitScore] = score
  const pic1 = '/app/images/onboarding/bg-thanks.jpg'

  const kit = kits.filter(aKit => aKit.id === KIT_MAP[kitScore])[0]
  const genderStr = gender === 'male' ? 'Male' : 'Female'

  useEffect(() => {
    setBackgroundImage(pic1, 'bg-image', 0.75)
  }, [props])

  useEffect(() => {
    if (section === 0) {
      setBackgroundImage(pic1, 'bg-image', 0.75)
    } else {
      setBackgroundImage('')
    }
  }, [section])

  const recommendedImage = () => {
    const g = gender === 'male' ? 'm' : 'f'
    const level = Math.min(4, Math.round(assessmentScore))
    // const details = showDetails ? 'hide' : 'see'
    if (kitScore === 1) {
      return `/app/images/recommendations/axis/${g}/gear-reco-axis-${g}-L${level}.jpg`
    }
    if (kitScore === 2) {
      return `/app/images/recommendations/fly/${g}/gear-reco-fly-${g}-L${level}.jpg`
    }
    return `/app/images/recommendations/power/${g}/gear-reco-power-${g}-L${level}.jpg`
  }

  return (
    <>
      {section === 0 ? (
        <section className="app-content assessment-done">
          <div className="app-content--inner">
            <h4 className="text--caps">Congratulations!</h4>
            <p>
              You made it through the onboarding. Thanks for taking the
              time—this process will improve your experience with Atom and help
              you be more successful in the long run.
            </p>
            <p>
              Next, we&apos;ll show you the Atom Kit and Level we think best
              suits your current needs.
            </p>

            <ul className="assessment--check-list">
              <li>
                <span>Profile</span>
                <Icon name="check" type="fal" size="lg" />
              </li>
              <li>
                <span>Assessment</span>
                <Icon name="check" type="fal" size="lg" />
              </li>
              <li>
                <span>Kit</span>
                <Icon name="check" type="fal" size="lg" />
              </li>
              <li>
                <span>Recommendation</span>
                <Icon name="check" type="fal" size="lg" />
              </li>
            </ul>
            <p>
              {kit.marketing} - L{Math.min(4, Math.round(assessmentScore))}{' '}
              {genderStr}
            </p>
            <div className="rpm-container--button-group">
              <p>
                <Button
                  label="See your recommended kit"
                  onClick={() => onNext()}
                  gtm="Atom-Kit-Recommendation-Click"
                />
              </p>
              <p>
                <Button label="Back" kind="link" onClick={() => onBack()} />
              </p>
            </div>
          </div>
        </section>
      ) : (
        <section className="app-content kit-recommendation">
          <div className="app-content--inner">
            <img src={recommendedImage()} alt="Power Kit" style={styles} />
            <h5 className="text--caps">
              We recommend you
              <br />
              {kit.label} L{Math.min(4, Math.round(assessmentScore))}
            </h5>
            <div className="rpm-container--button-group">
              <p>
                <Button label="Go to Training" onClick={() => onNext()} />
              </p>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default KitRecommendation
