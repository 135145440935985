import React from 'react'
import ReactDOM from 'react-dom'

import videojs from 'video.js'
import '@videojs/http-streaming'
import 'video.js/dist/video-js.css'

import {
  getRecentVideos,
  updateRecentVideo as _updateRecentVideo,
} from '../../Helpers'

import Icon from '../elements/Icon'
// import VideoPlayerOverlay from './VideoPlayerOverlay'
import { WorkoutOverlay } from './BrightcoveVideoPlayer'

const Plugin = videojs.getPlugin('plugin')

/**
 * VideoOverlayPlugin is a custom Video.js plugin that adds an overlay element to the video player.
 * The overlay element is positioned at the bottom of the player and spans the entire width and height.
 * 
 * @class VideoOverlayPlugin
 * @extends Plugin
 * 
 * @param {Player} player - The Video.js player instance.
 * @param {Object} options - An object containing plugin options.
 
 */
class VideoOverlayPlugin extends Plugin {
  constructor(player, options) {
    super(player, options)
    const overlayElement = document.createElement('div')
    const overlayItem = document.createElement('div')
    overlayElement.style.bottom = 0
    overlayElement.style.display = 'flex'
    overlayElement.style.height = '100%'
    overlayElement.style.left = '100%'
    overlayElement.style.position = 'absolute'
    overlayElement.style.right = 'auto'
    overlayElement.style.zIndex = 1
    overlayElement.style.textAlign = 'left'
    overlayElement.style.width = '100%'

    overlayItem.classList.add('workoutOverlay')
    overlayItem.style.height = '100%'

    overlayElement.appendChild(overlayItem)
    // overlayElement.classList.add('vjs-overlay')

    // Append the overlay to the player container
    player.el().appendChild(overlayElement)

    // // Listen for the 'play' event to show the overlay when the video starts
    // const playHandler = () => {
    //   // overlayElement.style.display = 'flex'
    // }

    // // Listen for the 'pause' event to hide the overlay when the video is paused
    // const pauseHandler = () => {
    //   // overlayElement.style.display = 'none'
    // }

    // player.on('mouseenter', playHandler)
    // player.on('mouseleave', pauseHandler)
  }
}

const playerSkipButton = (skipDirection, clickFn, label) => {
  const button = document.createElement('button')

  button.onclick = () => clickFn.call()
  button.title = label
  button.className = [
    'vjs-control',
    'vjs-button',
    'rpm-video--skip',
    `rpm-video--skip--${skipDirection}`,
  ].join(' ')

  return button
}

type VideoJSProps = {
  videoId: string,
  onReady?: () => void,
  className: String,
  onMetaDataLoad?: () => void,
  onEnded?: () => void,
  onError?: () => void,
  onCanPlay?: () => void,
  controls?: boolean,
  autoPlay?: boolean,
  muted?: boolean,
  aspectRatio?: string,
  id?: String,
  loop?: Boolean,
  preload?: 'auto' | 'metadata' | 'none',
  resume?: boolean,
  skipToTimeCode?: { time: Number, click: Number },
  workout?: Object,
  showWorkoutOverlay?: boolean,
  quality?: 'high' | 'medium' | 'low',
}

VideoJS.defaultProps = {
  onReady: undefined,
  onMetaDataLoad: undefined,
  onEnded: undefined,
  onError: undefined,
  onCanPlay: undefined,
  controls: true,
  autoPlay: false,
  muted: false,
  aspectRatio: undefined,
  id: undefined,
  loop: false,
  preload: 'auto',
  resume: true,
  skipToTimeCode: undefined,
  workout: undefined,
  showWorkoutOverlay: false,
  quality: undefined,
}

/**
 * VideoJS component initializes and manages a Video.js player instance.
 *
 * @param {VideoJSProps} props - The properties passed to the VideoJS component.
 * @param {string} props.videoId - The ID of the video to be played.
 * @param {function} [props.onReady] - Callback function to be called when the player is ready.
 * @param {string} [props.className] - Additional class names for the player container.
 * @param {function} [props.onMetaDataLoad] - Callback function to be called when metadata is loaded.
 * @param {function} [props.onEnded] - Callback function to be called when the video ends.
 * @param {function} [props.onError] - Callback function to be called when an error occurs.
 * @param {function} [props.onCanPlay] - Callback function to be called when the video can start playing.
 * @param {boolean} [props.autoPlay] - Whether the video should autoplay.
 * @param {boolean} [props.controls] - Whether to display video controls.
 * @param {boolean} [props.muted] - Whether the video should be muted.
 * @param {string} [props.aspectRatio] - The aspect ratio of the video.
 * @param {string} [props.id] - The ID for the video element.
 * @param {boolean} [props.loop] - Whether the video should loop.
 * @param {string} [props.preload] - The preload setting for the video.
 * @param {boolean} [props.resume] - Whether to resume the video from the last played position.
 * @param {Object} [props.skipToTimeCode] - Object containing time and click properties for skipping to a specific time code.
 * @param {Object} [props.workout] - Workout data to be displayed as an overlay.
 * @param {boolean} [props.showWorkoutOverlay] - Whether to show the workout overlay.
 * @param {string} [props.quality] - The quality setting for the video.
 *
 * @returns {JSX.Element} The VideoJS component.
 */
function VideoJS(props: VideoJSProps) {
  const videoRef = React.useRef()
  const playerRef = React.useRef()
  const {
    videoId,
    onReady,
    className,
    onMetaDataLoad,
    onEnded,
    onError,
    onCanPlay,
    autoPlay,
    controls,
    muted,
    aspectRatio,
    id,
    loop,
    preload,
    resume,
    skipToTimeCode,
    workout,
    showWorkoutOverlay,
    quality,
  } = props

  const [recentVideos, setRecentVideos] = React.useState(getRecentVideos())

  const updateRecentVideo = (aVideoId, progress) => {
    const updatedRecentVideos = _updateRecentVideo(aVideoId, progress)
    setRecentVideos(updatedRecentVideos)
  }

  const options = React.useMemo(() => {
    const sourceId = videoId.split('.')[1] || videoId
    return {
      aspectRatio,
      autoplay: autoPlay,
      controls,
      controlBar: {
        components: {
          // Include only the controls you want to keep
          playToggle: {},
          progressControl: {},
          volumePanel: {},
          fullscreenToggle: {},
          // Add other controls as needed
        },
      },
      crossOrigin: 'anonymous',
      disablePictureInPicture: true,
      fluid: true,
      id,
      html5: {
        vhs: {
          useNetworkInformationApi: true,
          limitRenditionByPlayerDimensions: false,
          // bandwidth: 5000000000,
          // overrideNative: true,
        },
      },
      loop,
      muted,
      playsinline: true,
      poster: undefined,
      preload,
      responsive: aspectRatio === undefined,
      // because we want to screen grab the highest resolution we should just point to the hi-res h264 stream
      sources: sourceId
        ? [
            {
              src: `/api/media/streaming/${sourceId}.m3u8${
                quality ? `?quality=${quality}` : ''
              }`,
              type: 'application/x-mpegURL',
            },
            // {
            //   src: `https://dev.trainatom.rpmtraining.com/out/${data.video.destinationLocation}.mpd`,
            //   type: "application/dash+xml",
            // },
          ]
        : undefined,
    }
  }, [
    controls,
    autoPlay,
    muted,
    id,
    aspectRatio,
    loop,
    preload,
    quality,
    videoId,
  ])

  const skipBackwards = () => {
    const p = playerRef.current
    const time = p.currentTime()

    p.currentTime(time - 15)
  }

  React.useEffect(() => {
    const p = playerRef.current
    if (p && skipToTimeCode?.time !== undefined) {
      p.currentTime(skipToTimeCode.time)
    }
  }, [skipToTimeCode])

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    window.VIDEOJS_NO_DYNAMIC_STYLE = true
    if (!playerRef.current && videoRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js')
      videoElement.className = 'video-js'
      videoElement.classList.add('vjs-big-play-centered')
      videoRef.current.appendChild(videoElement)
      if (showWorkoutOverlay && !videojs.getPlugin('videoOverlayPlugin'))
        videojs.registerPlugin('videoOverlayPlugin', VideoOverlayPlugin)

      const player = (playerRef.current = videojs(
        videoElement,
        { sources: [], ...options },
        () => {
          videojs.log('player is ready')
          player.autoplay(options.autoplay)
          player.src(options.sources)
          if (showWorkoutOverlay) player.videoOverlayPlugin()

          if (resume) {
            const lastProgress = recentVideos[videoId]?.progress || 0
            if (lastProgress < player.duration - 1)
              player.currentTime(lastProgress)
          }

          // load skip back button
          const backButton = playerSkipButton(
            'backward',
            skipBackwards,
            'Skip Backward 15 Seconds',
          )

          const controlBar =
            videoElement.getElementsByClassName('vjs-control-bar')[0]

          // Change the class name here to move the icon in the controlBar
          const insertBeforeNode =
            videoElement.getElementsByClassName('vjs-play-control')[0]

          // Insert the icon div in proper location
          controlBar.insertBefore(backButton, insertBeforeNode)

          ReactDOM.render(
            <Icon name="video-scrub-back15" type="svg" />,
            videoElement.querySelector('.rpm-video--skip--backward'),
          )

          // we have to control this manually because the brightcove player turns on window.VIDEOJS_NO_DYNAMIC_STYLE that effects any videojs player that we can't switch conditionally
          if (aspectRatio) {
            const [w, h] = aspectRatio.split(':', 2)
            videoElement.style.paddingTop = `${(h / w) * 100}%`
          }
          if (skipToTimeCode?.click !== 0 && skipToTimeCode?.time) {
            player.currentTime(skipToTimeCode.time)
          }

          if (onReady) onReady(player)
          setupMarkers()
        },
      ))

      player.on('canplay', () => {
        if (onCanPlay) onCanPlay(player)
      })

      player.on('ended', () => {
        if (onEnded) onEnded(player)
      })
      player.on('error', () => {
        if (onError) onError(player)
      })

      player.on('durationchange', () => {
        setupMarkers()
      })

      player.on('timeupdate', () => {
        const time = player.currentTime()

        if (resume) {
          updateRecentVideo(videoId, parseInt(time, 10))
        }
      })

      player.on('loadedmetadata', () => {
        if (onMetaDataLoad) onMetaDataLoad(player)
        setupMarkers()
      })

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current
      if (player) {
        // something set this to true which messes up styling. it must be brightcove but where and why?
        // window.VIDEOJS_NO_DYNAMIC_STYLE = undefined
        if (player.autoplay() !== options.autoplay) {
          player.autoplay(options.autoplay)
        } // if (player.paused() && options.autoplay) player.play()
        if (
          options.sources?.[0]?.src &&
          !player.src().endsWith(options.sources?.[0]?.src)
        ) {
          player.src(options.sources)
        }
        if (player.poster() !== options.poster && options.poster) {
          player.poster(options.poster)
        }
        if (player.aspectRatio() !== options.aspectRatio) {
          player.aspectRatio(options.aspectRatio)
        }
      }
    }
    // This is helpful to turn on in some instances for quick debug access in console
    window.videojs = playerRef.current
  }, [
    options,
    videoRef,
    aspectRatio,
    onEnded,
    onCanPlay,
    showWorkoutOverlay,
    resume,
    skipToTimeCode,
    onReady,
    recentVideos,
    videoId,
    onError,
    onMetaDataLoad,
  ])

  const setupMarkers = () => {
    const player = playerRef.current
    const videoDuration = player?.duration()
    const data = player?.mediainfo
    if (data?.cuePoints?.length > 0 && player && videoDuration > 0) {
      const progressBar = document.getElementsByClassName(
        'vjs-progress-control vjs-control',
      )[0]

      for (let i = 0; i < data?.cuePoints?.length; i += 1) {
        const cueName = data.cuePoints[i].name
        if (!['brief', 'end'].includes(cueName)) {
          let elem = document.getElementById(`cp${i}`)
          if (!elem) elem = document.createElement('button')
          elem.className = 'vjs-marker'
          elem.id = `cp${i}`
          elem.onmousedown = e => {
            // progress bar uses mousedown so we will for the chapter move as well
            player.currentTime(data.cuePoints[i].time)
            e.preventDefault()
            e.stopPropagation()
          }

          elem.style.left = `${(data.cuePoints[i].time / videoDuration) * 100}%`
          progressBar.appendChild(elem)
        }
      }
    }
  }

  React.useEffect(() => {
    if (!showWorkoutOverlay) return
    const player = playerRef.current
    const videoIdParts = videoId ? videoId.split('.', 2) : []
    const sourceId = videoIdParts.length > 1 ? videoIdParts[1] : videoId
    // Need to put the url for this into atom api
    fetch(`/api/media/${sourceId}`, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(clientData => {
        // Handle the fetched data on the client
        if (!player.isDisposed()) {
          player.mediainfo = clientData
          player.poster(clientData.poster)
          player.trigger('loadedmetadata')
          setupMarkers()
        }
      })
  }, [videoId, showWorkoutOverlay])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    if (typeof document === 'undefined') return

    const player = playerRef.current

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  const customClass = id !== undefined ? id : `video-id-${videoId}`

  return (
    <>
      {playerRef.current && showWorkoutOverlay && (
        <WorkoutOverlay
          workout={workout}
          player={playerRef.current}
          loaded={playerRef?.current?.duration() > 0}
          playerClass={customClass}
        />
      )}

      <div
        data-vjs-player
        className={`videojs-player brightcove-react-player-loader ${
          className || ''
        } ${customClass}`}
      >
        <div ref={videoRef} />
      </div>
    </>
  )
}

export default VideoJS
