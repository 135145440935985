import React from 'react'

import ReactMarkdown from 'react-markdown'
import { printClasses, ripple } from '../../Helpers'

import Button from '../../components/elements/Button'
import Markdown from '../../components/elements/Markdown'
import Icon from '../../components/elements/Icon'

type Props = {
  itemKey: string,
  onClick?: Object,
  selected?: boolean,
  primaryText?: string,
  secondaryText?: string,
  tooltipText?: string,
  onTooltipSelected?: Object,
  displayTooltip?: boolean,
  showSelectedCheck?: boolean,
}

AtomListItem.defaultProps = {
  onClick: null,
  selected: false,
  primaryText: '',
  secondaryText: '',
  tooltipText: '',
  onTooltipSelected: null,
  displayTooltip: false,
  showSelectedCheck: false,
}

/**
 * AtomListItem component renders a list item with various display options and interactions.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.itemKey - Unique key for the list item.
 * @param {string} props.primaryText - Primary text to display in the list item.
 * @param {string} props.secondaryText - Secondary text to display in the list item.
 * @param {string} props.tooltipText - Tooltip text to display when the tooltip is active.
 * @param {boolean} props.selected - Indicates if the list item is selected.
 * @param {function} props.onClick - Callback function to handle click events on the list item.
 * @param {function} props.onTooltipSelected - Callback function to handle tooltip selection.
 * @param {boolean} props.displayTooltip - Indicates if the tooltip should be displayed.
 * @param {boolean} props.showSelectedCheck - Indicates if the selected check icon should be shown.
 *
 * @returns {JSX.Element} The rendered list item component.
 */
function AtomListItem(props: Props) {
  const {
    itemKey,
    primaryText,
    secondaryText,
    tooltipText,
    selected,
    onClick,
    onTooltipSelected,
    displayTooltip,
    showSelectedCheck,
  } = props

  const onTap = onClick ? event => ripple(event) : () => {}

  return (
    <li
      key={itemKey}
      className={printClasses([
        'assessment--list--item',
        tooltipText ? 'has-tooltip' : '',
        displayTooltip ? 'is-open' : '',
        showSelectedCheck && onClick ? 'is-selectable' : '',
        selected ? 'is-selected' : '',
      ])}
    >
      <div
        className="assessment--option"
        onClick={onClick}
        onMouseDown={event => onTap(event)}
        onKeyPress={() => {}}
        selected={selected}
        role={onClick ? 'button' : 'text'}
        tabIndex="0"
      >
        {showSelectedCheck && (
          <div className="assessment--option--icon">
            <Icon name={selected ? 'check' : 'missing'} type="fal" />
          </div>
        )}

        <div className="assessment--option--content">
          {primaryText && (
            <div className="assessment--text assessment--text--primary">
              <ReactMarkdown>{primaryText}</ReactMarkdown>
            </div>
          )}

          {secondaryText && (
            <p
              className={printClasses([
                'assessment--text',
                'assessment--text--secondary',
                primaryText && 'ghost',
              ])}
            >
              <small>{secondaryText}</small>
            </p>
          )}

          {tooltipText && (
            <Markdown
              key={`${itemKey}-tooltip`}
              className="assessment--tooltip ghost"
              // eslint-disable-next-line react/no-danger
            >
              {tooltipText}
            </Markdown>
          )}
        </div>
      </div>
      {tooltipText && (
        <Button
          kind="secret"
          onClick={onTooltipSelected}
          cnames="assessment--tooltip--cue"
          icon={{
            name: displayTooltip ? 'times-circle' : 'question-circle',
            type: 'fal',
            size: 'lg',
          }}
          canRipple={false}
        />
      )}
    </li>
  )
}
export default AtomListItem
