import React, { useState, useEffect } from 'react'

import {
  useComments,
  useSetComment,
  useDeleteComment,
  useTraining,
  useWorkshopTraining,
} from '../../context/atomContext'
import { useMentions } from '../../hooks/TextHooks'

import {
  printClasses,
  scrollToElement,
  displayTrackName,
  getTrack,
  dateMe,
} from '../../Helpers'

import UserProfileRow from '../structures/UserProfileRow'
import CommentRow from '../structures/CommentRow'
import TrainingScore from '../structures/TrainingScore'
import Mentions from '../structures/Mentions'

import WorkoutOverlay from '../../routes/parts-for-training/WorkoutOverlay'

import ConfirmationDialog from '../molecules/ConfirmationDialog'
import LoadingData from '../molecules/LoadingData'

import AtomMarkup from '../elements/AtomMarkup'
import Button from '../elements/Button'
import IconText from '../elements/IconText'
import Panel from '../elements/Panel'

import InputGroup from '../inputs/InputGroup'
import Textarea from '../inputs/Textarea'

type Props = {
  isOpen: boolean,
  handleDismiss: Object,
  commentItem: Object,
  openProfile: Object,
  zIndex?: number,
}

Comments.defaultProps = {
  zIndex: null,
}

/**
 * Comments component renders a panel with a workout, comments, and training data.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.isOpen - Indicates if the comments panel is open.
 * @param {function} props.handleDismiss - Function to handle the dismissal of the panel.
 * @param {Object} props.commentItem - The comment item data.
 * @param {function} props.openProfile - Function to open the user profile.
 * @param {number} props.zIndex - The z-index of the panel.
 *
 * @returns {JSX.Element} The Comments component.
 */
function Comments(props: Props) {
  const { isOpen, handleDismiss, commentItem, openProfile, zIndex } = props

  const mentions = useMentions(undefined, 'commentInput')
  const [commentToDelete, setCommentToDelete] = useState(null)
  const track = getTrack(commentItem?.track)

  // API
  const { isLoading, data, isError, refetch } = useComments(
    commentItem?.id || undefined,
    commentItem?.track || 'atomic',
    undefined,
    commentItem?.config,
  )

  const { data: trackTrainingData, isFetching: isTrackTrainingLoading } =
    useTraining(
      commentItem?.date,
      commentItem?.date !== undefined &&
        !['workshop', 'manual'].includes(commentItem?.track),
      commentItem?.track,
      {
        userId: commentItem?.userId || '',
      },
    )

  const { data: workshopTrainingData, isFetching: isWorkshopDataFetching } =
    useWorkshopTraining(
      commentItem?.day,
      commentItem?.workshopId,
      commentItem?.workshopId !== undefined &&
        commentItem?.track === 'workshop',
    )

  const trainingData =
    commentItem?.track === 'workshop' ? workshopTrainingData : trackTrainingData

  const isTrainingLoading = isTrackTrainingLoading || isWorkshopDataFetching

  const isAnythingLoading = isLoading || isTrainingLoading

  const mentionResults = mentions?.userSearchData || []

  const postCommentAPI = useSetComment(commentItem?.config)
  const deleteComment = useDeleteComment(commentItem?.config)

  const commentTextClasses = [
    'form-area--comment',
    postCommentAPI.isError && 'has-error',
  ]

  const firstItem =
    trainingData?.value?.rows.length > 0
      ? trainingData?.value?.rows[0]
      : undefined

  const userTrainingData = firstItem?.userTraining

  const workoutData =
    firstItem?.id !== undefined &&
    (firstItem?.id === commentItem?.trainingId ||
      (firstItem?.day === commentItem?.day &&
        firstItem?.workshopId === commentItem?.workshopId))
      ? firstItem
      : undefined
  const comments = data?.value?.rows && [...data.value.rows].reverse()
  const showCaption = !!commentItem?.caption

  // function
  const trainingUrl = () => {
    if (commentItem?.track !== 'workshop')
      return `/app/${commentItem?.track || 'atomic'}/training?date=${
        workoutData?.date
      }&kit=${commentItem?.kit}&fromJournal=1`
    return `/app/workshops/${
      commentItem?.workshop?.slug ||
      commentItem?.training?.slug ||
      firstItem?.workshopSlug
    }/training?day=${commentItem?.day}&fromJournal=1`
  }
  const postComment = () => {
    const [theComment, userMentions] = mentions?.getPostData()

    postCommentAPI.mutate(
      {
        [commentItem?.config?.commentIdName || 'userTrainingId']:
          commentItem.id,
        track: commentItem?.track,
        comment: theComment,
        mentions: userMentions,
      },
      {
        onSuccess: () => {
          mentions?.setMentionText('')
          mentions?.setMentionedUsers([])
          if (commentItem?.onCommentSuccess) commentItem.onCommentSuccess()
        },
        onError: anError => {
          console.log(anError)
        },
      },
    )
  }

  const commentChanged = event => {
    mentions?.setMentionText(event.target.value)
  }

  const addUserToComment = user => {
    mentions?.addUserToComment(user)
  }

  const dismissDialog = () => setCommentToDelete(null)

  useEffect(
    () => scrollToElement('.app-comments--row:last-child'),
    [isOpen, isAnythingLoading, data],
  )

  // Fragments
  const footer = (
    <>
      <div className="app-comments--input is-open">
        <InputGroup columns="1fr auto">
          <Mentions
            rows={mentionResults}
            search={mentions?.userToSearch}
            selectedIndex={mentions?.searchSelectedIndex}
            action={addUserToComment}
          >
            <Textarea
              id="commentInput"
              name="commentInput"
              placeholder="Add a comment"
              value={mentions?.mentionText}
              onKeyDown={mentions?.keyWatcher}
              changed={commentChanged}
              cnames={printClasses(commentTextClasses)}
            />
          </Mentions>

          <Button
            label="Post"
            disabled={
              isError || !mentions?.mentionText || postComment.isLoading
            }
            onClick={postComment}
          />
        </InputGroup>
      </div>
    </>
  )

  const heels = (
    <>
      <LoadingData isLoading={isAnythingLoading} />

      <ConfirmationDialog
        isOpen={commentToDelete}
        header="Delete Comment"
        primaryText="Delete"
        primaryAction={() => {
          deleteComment.mutate(
            {
              id: commentToDelete,
              threadId: commentItem?.id,
              track: commentItem?.track,
            },
            {
              onSuccess: () => {
                dismissDialog()
                if (commentItem?.onCommentSuccess)
                  commentItem.onCommentSuccess()
              },
              onError: anError => {
                console.log(anError)
                dismissDialog()
              },
            },
          )
        }}
        secondaryText="Cancel"
        secondaryAction={dismissDialog}
        cnames="app-comments--delete-dialog"
      >
        <p>
          <b>Are you sure you want to delete this comment?</b>
        </p>

        <p>This can&apos;t be undone.</p>
      </ConfirmationDialog>
    </>
  )

  const poster =
    commentItem?.track === 'persist'
      ? '/app/images/persist/FBB_Gradients.jpg'
      : workoutData?.media?.main?.poster || workoutData?.video?.main?.poster
  const bgSize =
    commentItem?.track === 'persist' || poster === undefined
      ? 'cover'
      : 'contain'
  const validData =
    workoutData?.date === commentItem?.date ||
    workoutData?.day === commentItem?.day

  const workoutTitle =
    workoutData?.title ||
    commentItem?.title ||
    firstItem?.title ||
    `${displayTrackName(commentItem?.track) || ''} ${dateMe(
      commentItem?.date,
      'title',
    )}`

  const workoutSubTitle =
    workoutData?.subtitle || commentItem?.subtitle || firstItem?.subtitle
  return (
    <Panel
      isOpen={isOpen}
      isLoading={isAnythingLoading}
      handleDismiss={handleDismiss}
      header={track ? 'Training Summary' : 'Comments'}
      footer={footer}
      heels={heels}
      opensFrom="right"
      cnames="app-comments"
      zIndex={zIndex}
    >
      {commentItem && (
        <>
          {validData && (
            <>
              <header
                className="rpm-hero flex--auto-spread"
                style={{
                  '--header-bg-image': `url(${
                    poster || '/app/images/manual-workout-evergreen.jpg'
                  })`,
                  '--header-background-size': bgSize,
                  aspectRatio: '16/9',
                }}
              >
                <div className="rpm-block text--small w-full">
                  <h1 className="text--caps">{workoutTitle}</h1>
                  <h2 className="text--caps">{workoutSubTitle}</h2>
                  {track?.noTraining !== true && (
                    <div className="rpm-workout-link text--small text--700 text--link">
                      <a href={trainingUrl()}>
                        <IconText
                          text="Go to this workout"
                          icon={{
                            name: 'arrow-circle-right',
                            type: 'fas',
                            size: 'lg',
                          }}
                        />
                      </a>
                    </div>
                  )}
                  <div className="rpm-workout-link text--small text--700 text--link">
                    <a href="/app/journal">
                      <IconText
                        text="Open training journal"
                        icon={{
                          name: 'notebook',
                          type: 'fas',
                          size: 'lg',
                        }}
                      />
                    </a>
                  </div>
                </div>
                <TrainingScore
                  data={{ ...userTrainingData, ...commentItem }} // this needs to not combine if it doesn't need to
                  isLight
                />
              </header>
            </>
          )}

          {workoutData && !isTrainingLoading && trainingData && (
            <div className="fg--white bg--black rpm-workout text--small">
              <WorkoutOverlay
                activeWorkout={workoutData?.workout[commentItem.kit]?.training}
                sectionNotes={
                  commentItem.sectionNotes || userTrainingData?.sectionNotes
                }
                workoutHeading=""
                workoutFormat={workoutData.workout?.format}
                defaultLevel="l1"
                movementSelections={
                  commentItem.selections || userTrainingData?.selections
                }
                workoutNotes={commentItem.notes || userTrainingData?.notes}
                tracking={commentItem.tracking || userTrainingData?.tracking}
              />
            </div>
          )}

          <UserProfileRow
            user={{ ...userTrainingData, ...commentItem }}
            avatarSize={3}
            openProfile={() => openProfile(commentItem.userId)}
            showKitLevel={false}
            showUsernameKitLevel
            cnames="app-comments--user"
          >
            {showCaption && (
              <>
                <div className="flex--auto-spread">
                  <p className="app-comments--comment">
                    <AtomMarkup openProfile={openProfile}>
                      {commentItem.caption}
                    </AtomMarkup>
                  </p>
                </div>

                {commentItem.timeAgo && (
                  <p className="ghost">
                    <small>{commentItem.timeAgo}</small>
                  </p>
                )}
              </>
            )}
          </UserProfileRow>

          <div className="app-comments--inner">
            {!isAnythingLoading &&
              !isError &&
              comments.map(row => (
                <CommentRow
                  row={row}
                  key={row.id}
                  openProfile={openProfile}
                  deleteComment={() => setCommentToDelete(row.id)}
                />
              ))}

            {isError && (
              <div className="app-header--error--content rpm-container">
                <p>
                  <strong>We experienced an internal issue.</strong>
                </p>

                <p>
                  Please retry. If you continue to see this error, please
                  contact RPM customer support.
                </p>

                <p className="text--center">
                  <Button
                    label="Retry"
                    kind="danger"
                    onClick={() => refetch()}
                  />
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </Panel>
  )
}

export default Comments
