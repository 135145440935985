import React, { useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import AppLayout from '../components/layout/Layout'
import Assessment from './parts-for-onboarding/Assessment'

import PersistTourSummary from '../components/screens/PersistTourSummary'

import { setBackgroundImage, getTour } from '../Helpers'
import { useAddTourResponse } from '../context/atomContext'

/**
 * Tour component handles the display and logic for a tour.
 * It manages the state of tour responses, completion status, and navigation.
 *
 * @component
 * @returns {JSX.Element} The rendered Tour component.
 *
 * @typedef {Object} TrackObj
 * @property {string} title - The title of the tour.
 * @property {string} target - The target destination after the tour.
 * @property {string} targetQuestionId - The ID of the target question.
 * @property {Array<Object>} questions - The list of questions in the tour.
 * @property {string} showSummary - The type of summary to show after completion.
 *
 * @typedef {Object} Question
 * @property {string} id - The ID of the question.
 * @property {Array<Object>} answers - The list of possible answers.
 *
 * @typedef {Object} Answer
 * @property {string} id - The ID of the answer.
 * @property {string} target - The target destination for this answer.
 *
 * @typedef {Object} Response
 * @property {string} id - The ID of the response.
 * @property {Array<string>} answers - The list of answers for the response.
 *
 * @typedef {Object} SummaryComponent
 * @property {Function} persist - The component to show for the persist summary.
 *
 * @function tourTarget
 * @description Determines the target destination based on the responses.
 * @param {Object} responses - The responses given during the tour.
 * @returns {string} The target destination.
 *
 * @function completeTour
 * @description Completes the tour and navigates to the appropriate destination.
 * @param {Object} responses - The responses given during the tour.
 *
 * @function exitTour
 * @description Exits the tour and navigates to the previous or target tour.
 */
function Tour() {
  // State
  const [tourResponses, setTourResponses] = useState({})
  const [isCompleted, setIsCompleted] = useState(false)

  const { track } = useParams()
  const location = useLocation()
  const sendTourResponse = useAddTourResponse()
  const queryParams = new URLSearchParams(location.search)
  const history = useHistory()
  const tourId = queryParams.get('id') || track
  const previousTour = queryParams.get('previousTour')
  const trackObj = getTour(tourId)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /**
   * Determines the target destination based on the provided responses and track object.
   *
   * @param {Object} responses - The responses object containing answers to questions.
   * @returns {string} - The target destination URL.
   */
  const tourTarget = responses => {
    let destination = '/'
    if (trackObj?.target) {
      destination = trackObj?.target
    }
    if (
      trackObj?.targetQuestionId &&
      responses[trackObj?.targetQuestionId]?.length
    ) {
      const question = trackObj.questions.find(
        q => q.id === trackObj?.targetQuestionId,
      )
      const answer = responses[trackObj?.targetQuestionId][0]
      const answerDetails = question.answers.find(a => a.id === answer)
      if (answerDetails?.target) {
        destination = answerDetails.target
      }
    }
    return destination
  }

  if (!trackObj) history.push('/')

  const summaryComponents = {
    persist: PersistTourSummary,
  }

  const SummaryComponent =
    trackObj?.showSummary && summaryComponents[trackObj?.showSummary]

  const completeTour = responses => {
    sendTourResponse.mutate({
      id: tourId,
      responses,
    })
    if (trackObj?.showSummary && SummaryComponent) {
      setIsCompleted(true)
    } else {
      history.push(tourTarget(responses))
    }
  }

  const exitTour = () => {
    history.push(
      previousTour ? `/tour?id=${previousTour}` : tourTarget(tourResponses),
    )
  }

  return (
    <AppLayout
      name="onboarding"
      headerConfig={{ label: trackObj?.title || 'Tour' }}
      cnames={
        isCompleted
          ? 'one-column-content ui-dark is-full-width'
          : 'one-column-content ui-dark'
      }
    >
      <article className="app-content">
        <section className="app-content--inner tab-content-assessment">
          {trackObj && !isCompleted && (
            <Assessment
              key={tourId}
              setBackgroundImage={setBackgroundImage}
              onNext={completeTour}
              onExitAssessment={exitTour}
              responses={tourResponses}
              updateResponse={setTourResponses}
              questions={trackObj?.questions || []}
              hideOnboardingSkip
              startingQuestion={
                // tourResponses[
                //   trackObj?.questions[trackObj?.questions?.length - 1].id
                // ]
                //   ? trackObj?.questions?.length - 1
                //   : undefined
                undefined
              }
            />
          )}
          {isCompleted && SummaryComponent && (
            <SummaryComponent
              responses={tourResponses}
              onBack={() => setIsCompleted(false)}
            />
          )}
        </section>
      </article>
    </AppLayout>
  )
}

export default Tour
