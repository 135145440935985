import { useEffect } from 'react'
import { useImmer } from 'use-immer'

import { useProfile } from '../context/atomContext'

import { getTrack } from '../Helpers'

/**
 * Custom hook to manage support-related functionalities.
 *
 * @returns {Object} An object containing:
 * - `openSupport`: Function to open the support interface.
 * - `supportTracks`: Array of support tracks with user data.
 * - `numSupportMessages`: Object representing the number of unread support messages.
 */
export function useSupport() {
  const { data: profileData } = useProfile()
  const [numSupportMessages, setNumSupportMessages] = useImmer({})

  useEffect(() => {
    const listen = e =>
      setNumSupportMessages(draft => {
        draft.persist = e?.detail?.unreadCount || 0
      })

    window.addEventListener('atomSupportUnreadCountChange', listen)

    return () =>
      window.removeEventListener('atomSupportUnreadCountChange', listen)
  }, [setNumSupportMessages])

  const subscriptions = profileData?.value?.subscriptions
  const support = profileData?.value?.support

  const supportTracks =
    subscriptions &&
    Object.keys(subscriptions)
      .filter(k => subscriptions[k])
      .map(k => {
        const track = getTrack(k)
        return { ...track, supportUserData: support[k] }
      })
      .filter(t => t?.support !== undefined)

  const openSupport = (track: String | undefined) => {
    if (supportTracks.length >= 1) {
      let activeSupportTrack = supportTracks[0]
      if (track) {
        const aTrack = getTrack(track)
        if (aTrack?.support) activeSupportTrack = aTrack
      }
      switch (activeSupportTrack.support.kind) {
        case 'intercom':
          window.Intercom('show')
          break
        default:
          window.zE('messenger', 'show')
          window.zE('messenger', 'open')
          break
      }
    } else {
      window.zE('messenger', 'show')
      window.zE('messenger', 'open')
    }
  }

  return { openSupport, supportTracks, numSupportMessages }
}
export default useSupport
