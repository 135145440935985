import React, { createContext, useContext, useCallback } from 'react'
import { useImmerReducer } from 'use-immer'
import { current } from 'immer'

import useFeature from '../hooks/FeatureHooks'

import {
  useSubmitScore,
  useDeleteScore as useDeleteScoreCtx,
} from './atomContext'

import { calcTimeString } from '../Helpers'
import { useDebounceEffect } from '../hooks/EffectHooks'

const isDebug = ['development', 'test'].includes(process.env.NODE_ENV)

export const TrainingContext = createContext()
type TrainingProviderProps = {
  children: object,
  defaultState?: object,
}

const defaultScore = {
  haveScore: true,
  visible: undefined,
  beatCap: true,
  reps: '',
  time: 0,
  weight: '',
  perceivedEffort: undefined,
  feltLike: undefined,
  notes: '',
  caption: '',
  tiebreak: '',
}

/**
 * Reducer function to manage the state of training data.
 *
 * @param {Object} draft - The current state of the training data.
 * @param {Object} action - The action to be performed on the state.
 * @param {string[]} action.keyPath - Array containing track, date, kit, and key.
 * @param {boolean} [action.debugMode] - Flag to enable debug logging.
 * @param {string} action.type - The type of action to be performed.
 * @param {any} [action.value] - The value to be used in the action.
 * @param {string} [action.id] - The ID used in tracking updates.
 * @param {number} [action.round] - The round number used in tracking updates.
 * @param {boolean} [action.skipAutoSave] - Flag to skip auto-saving the state.
 *
 * @returns {void}
 */
const trainingReducer = (draft, action) => {
  const [track, date, kit, key] = action.keyPath
  if (action.debugMode) console.log(action)
  switch (action.type) {
    // Set the active training that is on the screen
    case 'setActiveTraining': {
      draft.activeTraining = action.value
      break
    }
    // Reset the score for a given training
    case 'resetScore': {
      if (!draft?.[track]) draft[track] = {}
      if (!draft?.[track]?.[date]) draft[track][date] = {}
      if (!draft?.[track]?.[date]?.[kit]) draft[track][date][kit] = {}
      draft[track][date][kit].movementSelections = []
      draft[track][date][kit].sectionNotes = {}
      draft[track][date][kit].score = { ...defaultScore }
      draft[track][date][kit].tracking = {}
      delete draft.activeTraining
      break
    }
    // Update the local score for a given training
    case 'updateScore': {
      if (!draft?.[track]) draft[track] = {}
      if (!draft?.[track]?.[date]) draft[track][date] = {}
      if (!draft?.[track]?.[date]?.[kit]) draft[track][date][kit] = {}
      if (key === undefined) {
        draft[track][date][kit].score = { ...defaultScore, ...action.value }
      } else if (!draft?.[track]?.[date]?.[kit]?.score)
        draft[track][date][kit].score = {
          ...defaultScore,
          [key]: action.value,
        }
      else {
        const currentScore = draft[track][date][kit].score
        draft[track][date][kit].score = {
          ...currentScore,
          [key]: action.value,
        }
      }
      break
    }
    // update the local tracking for a given training
    case 'updateTracking': {
      const { id, round } = action
      if (!draft?.[track]) draft[track] = {}
      if (!draft?.[track]?.[date]) draft[track][date] = {}
      if (!draft?.[track]?.[date]?.[kit]) draft[track][date][kit] = {}
      if (key === undefined) {
        draft[track][date][kit].tracking = { ...action.value }
        console.log('setting tracking', action.value)
      } else if (!draft?.[track]?.[date]?.[kit]?.tracking) {
        const value = []
        value[round] = action.value
        draft[track][date][kit].tracking = {
          [id]: { [key]: value },
        }
      } else {
        const currentTracking = draft[track][date][kit].tracking[id] || {}
        let value = []
        if (currentTracking[key]) value = currentTracking[key]
        value[round] = action.value
        draft[track][date][kit].tracking[id] = {
          ...currentTracking,
          [key]: value,
        }
      }
      break
    }
    // Update the local section notes for a given training
    case 'updateSectionNotes': {
      if (!draft?.[track]) draft[track] = {}
      if (!draft?.[track]?.[date]) draft[track][date] = {}
      if (!draft?.[track]?.[date]?.[kit]) draft[track][date][kit] = {}
      if (key === undefined) {
        draft[track][date][kit].sectionNotes = action.value
      } else if (!draft?.[track]?.[date]?.[kit]?.sectionNotes) {
        draft[track][date][kit].sectionNotes = {
          [key]: action.value,
        }
      } else {
        const currentSectionNotes = draft[track][date][kit].sectionNotes
        draft[track][date][kit].sectionNotes = {
          ...currentSectionNotes,
          [key]: action.value,
        }
      }
      break
    }
    // Update the local movement selections for a given training
    case 'updateMovementSelections': {
      if (!draft?.[track]) draft[track] = {}
      if (!draft?.[track]?.[date]) draft[track][date] = {}
      if (!draft?.[track]?.[date]?.[kit]) draft[track][date][kit] = {}
      draft[track][date][kit].movementSelections = JSON.parse(
        JSON.stringify(action.value),
      )
      console.log(
        'test',
        action.value,
        draft[track][date][kit].movementSelections,
      )
      break
    }
    default:
      break
  }
  if (!action.skipAutoSave && action.type !== 'updateMovementSelections') {
    // When autosave is enabled save the current state into activeTraining
    const currentTraining = current(draft)
    draft.activeTraining = {
      ...currentTraining[track][date][kit],
      track,
      date,
      kit,
    }
  }
}

/**
 * TrainingProvider component that provides training context to its children.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components.
 * @param {Object} props.defaultState - The default state for the training context.
 * @param {Object} props.defaultState - The default state for the training context.
 * @returns {JSX.Element} The TrainingProvider component.
 *
 * @typedef {Object} TrainingProviderProps
 * @property {React.ReactNode} children - The child components.
 * @property {Object} defaultState - The default state for the training context.
 *
 * @typedef {Object} TrainingState
 * @property {Object} activeTraining - The active training state.
 *
 * @typedef {Object} TrainingContextValue
 * @property {TrainingState} state - The current state of the training context.
 * @property {Function} dispatch - The dispatch function to update the state.
 * @property {Function} getValue - Function to get a value from the state.
 * @property {Function} saveScore - Function to save a score.
 * @property {boolean} isSavingScore - Indicates if a score is being saved.
 * @property {Function} deleteScore - Function to delete a score.
 * @property {boolean} debugMode - Indicates if debug mode is enabled.
 * @property {Object} defaultAction - Default actions available in the context.
 */
const TrainingProvider = ({
  children,
  defaultState,
}: TrainingProviderProps) => {
  const trainingDebug = useFeature('training-debug')
  const debugMode = isDebug || trainingDebug

  const { mutate: submitScore, isLoading: isSavingScore } = useSubmitScore()
  const { mutate: deleteAScore } = useDeleteScoreCtx()

  const [state, dispatch] = useImmerReducer(trainingReducer, defaultState)

  const getValue = callback => callback(state)

  /**
   * Autosaves the training data if certain conditions are met.
   *
   * @function
   * @param {Object} val - The training data to be autosaved.
   * @param {Object} val.score - The score object containing various properties.
   * @param {string} val.score.status - The status of the score (e.g., 'published').
   * @param {string} val.score.trainingId - The ID of the training.
   * @param {string} val.track - The track of the training.
   * @param {string} val.kit - The kit used in the training.
   * @param {string} val.date - The date of the training.
   * @param {string} val.sectionNotes - Notes for the training sections.
   * @param {string} val.tracking - Tracking information for training sections.
   * @param {Array} val.movementSelections - Selections of movements.
   * @param {string} [val.score.notes] - Additional notes.
   * @param {string} [val.score.caption] - Caption for the score.
   * @param {number} [val.score.reps] - Number of repetitions.
   * @param {number} [val.score.weight] - Weight used.
   * @param {number} [val.score.time] - Time taken.
   * @param {boolean} [val.score.beatCap=true] - Whether the beat cap is enabled.
   * @param {string} [val.score.tiebreak] - Tiebreak information.
   * @param {number} [val.score.perceivedEffort] - Perceived effort level.
   * @param {string} [val.score.feltLike] - Description of how it felt.
   * @param {boolean} [val.score.visible=false] - Visibility status.
   * @param {string} [val.score.id] - ID of the score.
   * @param {string} [val.score.workshopId] - ID of the workshop.
   * @param {string} [val.score.day] - Day of the workshop.
   * @param {Function} submitScore - Function to submit the score.
   * @param {boolean} debugMode - Flag to enable debug mode.
   */
  const autosaveTraining = useCallback(
    val => {
      if (!val) return
      if (val.score.status === 'published') return // do not auto save to published workouts
      let newDraftData = {
        trainingId: val.score.trainingId,
        track: val.track,
        kit: val.kit,
        date: val.date,
        sectionNotes: val.sectionNotes,
        tracking: val.tracking,
        selections: val.movementSelections || [],
        notes: val.score?.notes || '',
        caption: val.score?.caption || '',
        reps: val.score?.reps || undefined,
        weight: val.score?.weight || undefined,
        time: val.score?.time || undefined,
        beatCap: val.score?.beatCap || true,
        tiebreak: val.score?.tiebreak || undefined,
        perceivedEffort: val.score.perceivedEffort || undefined,
        feltLike: val.score.feltLike || undefined,
        completedOnly: true,
        visible: val.score.visible || false,
        status: 'draft',
      }
      if (val?.score?.id) newDraftData.id = val.score.id
      if (val.score?.workshopId) {
        // this is a workshop so set it up as such
        const additions = {
          workshopId: val.score?.workshopId,
          day: val.score?.day,
          track: 'workshop',
        }
        newDraftData = { ...newDraftData, ...additions }
        delete newDraftData.date
      }

      submitScore(newDraftData)
      if (debugMode) console.log('training changed', newDraftData)
    },
    [submitScore, debugMode],
  )
  useDebounceEffect(state.activeTraining, 5000, autosaveTraining)

  const deleteScore = (keyPath = undefined, score = undefined) => {
    if (score) {
      deleteAScore(score)
    } else {
      const [track, date, kit] = keyPath
      const scoreToDelete = state?.[track]?.[date]?.[kit]

      let myTrack = track
      if (scoreToDelete.score?.track === 'workshop') myTrack = 'workshop'

      deleteAScore({ id: scoreToDelete.score.id, track: myTrack })
    }
  }

  /**
   * Saves the score for a given training session.
   *
   * @param {Array} keyPath - An array containing the track, date, and kit identifiers.
   * @param {Array} mentionsPostData - An array containing the caption and mention IDs.
   * @param {Array} selections - An array of selections.
   * @param {string} level - The level of the training.
   * @param {Array} scoring - An array of scoring methods.
   * @param {Function} onSubmit - A callback function to be called upon successful submission.
   * @param {Object} postAdditions - Additional data to be added to the draft data.
   */
  const saveScore = (
    keyPath,
    mentionsPostData,
    selections,
    level,
    scoring,
    onSubmit,
    postAdditions,
  ) => {
    const [track, date, kit] = keyPath
    const scoreToSubmit = state?.[track]?.[date]?.[kit]
    const localScoring = scoreToSubmit.score?.scoring || scoring[0]

    // cancel any current auto save
    dispatch({
      type: 'setActiveTraining',
      keyPath,
      value: undefined,
      skipAutoSave: true,
    })

    // We don't have anything for this data, TODO error
    if (!scoreToSubmit) return

    const [theCaption, mentionIds] = mentionsPostData

    let score

    if (Object.is(localScoring, 'reps')) {
      score = Number(scoreToSubmit.score.reps)
    }

    if (
      scoreToSubmit.score.beatCap &&
      scoreToSubmit.score.time &&
      Object.is(localScoring, 'time')
    ) {
      score = calcTimeString(scoreToSubmit.score.time)
      if (scoreToSubmit.score.timecap) {
        // if we have a timecap you can never submit a score greater than it
        const maxScore = calcTimeString(`${scoreToSubmit.score.timecap}:00`)
        if (maxScore < score) score = maxScore
      }
    } else if (
      !scoreToSubmit.score.beatCap &&
      Object.is(localScoring, 'time')
    ) {
      score = calcTimeString(`${scoreToSubmit.score.timecap}:00`)
    }

    if (Object.is(localScoring, 'weight')) {
      score = Number(scoreToSubmit.score.weight)
    }

    if (!score) score = 0

    const haveScore =
      (scoreToSubmit.score?.haveScore && score !== 0) ||
      (!scoreToSubmit?.score?.beatCap && scoreToSubmit?.score?.tiebreak !== 0)

    let newDraftData = {
      trainingId: scoreToSubmit.score.trainingId,
      track,
      kit,
      date,
      level,
      sectionNotes: scoreToSubmit.sectionNotes,
      tracking: scoreToSubmit.tracking,
      notes: scoreToSubmit.score?.notes || '',
      caption: theCaption,
      mentions: mentionIds,
      selections: selections || [],
      perceivedEffort: scoreToSubmit.score?.perceivedEffort || undefined,
      feltLike: scoreToSubmit.score?.feltLike || undefined,
      completedOnly: !haveScore,
      visible: scoreToSubmit.score.visible || false,
      status: 'published',
    }
    if (scoreToSubmit?.score?.id) newDraftData.id = scoreToSubmit.score.id

    if (scoreToSubmit.score?.haveScore && score !== 0) {
      const additions = {
        score,
        beatCap: true,
      }
      newDraftData = { ...newDraftData, ...additions }
    }
    if (
      !scoreToSubmit?.score?.beatCap &&
      scoreToSubmit?.score?.tiebreak !== 0
    ) {
      const additions = {
        beatCap: scoreToSubmit.score?.beatCap,
        tiebreak: Number(scoreToSubmit.score?.tiebreak) || null,
        score,
      }
      newDraftData = { ...newDraftData, ...additions }
    }

    if (scoreToSubmit.score?.workshopId) {
      // this is a workshop so set it up as such
      const additions = {
        workshopId: scoreToSubmit.score?.workshopId,
        day: scoreToSubmit.score?.day,
        track: 'workshop',
      }
      newDraftData = { ...newDraftData, ...additions }
      delete newDraftData.date
    }
    if (postAdditions) newDraftData = { ...newDraftData, ...postAdditions }
    if (debugMode) console.log(newDraftData)
    submitScore(newDraftData, {
      onSuccess: () => {
        const previousStatus = scoreToSubmit.score?.status || 'draft'
        dispatch({
          type: 'updateScore',
          keyPath: [...keyPath, 'status'],
          value: 'published',
          skipAutoSave: true,
        })

        if (onSubmit) onSubmit(kit, previousStatus)
      },
      onError: anError => {
        console.log(anError)
      },
    })
  }

  const defaultAction = {
    debugMode,
  }

  return (
    <TrainingContext.Provider
      value={{
        state,
        dispatch,
        getValue,
        saveScore,
        isSavingScore,
        deleteScore,
        debugMode,
        defaultAction,
      }}
    >
      {children}
    </TrainingContext.Provider>
  )
}
TrainingProvider.defaultProps = {
  defaultState: {},
}

export default TrainingProvider

// A series of hooks that provide access to the training context, its data, and its methods.

export const useSelector = callback => {
  const { getValue } = useContext(TrainingContext)
  return getValue(callback)
}

export const useScore = keyPath => {
  const [track, date, kit] = keyPath

  return useSelector(
    state => state?.[track]?.[date]?.[kit]?.score || defaultScore,
  )
}

export const useUpdateScore = () => {
  const { dispatch, defaultAction } = useContext(TrainingContext)

  const updateScore = (keyPath, value, skipAutoSave = false) => {
    dispatch({
      ...defaultAction,
      type: 'updateScore',
      keyPath,
      value,
      skipAutoSave,
    })
  }

  return updateScore
}

export const useTracking = keyPath => {
  const [track, date, kit] = keyPath

  return useSelector(state => state?.[track]?.[date]?.[kit]?.tracking || {})
}

export const useUpdateTracking = () => {
  const { dispatch, defaultAction } = useContext(TrainingContext)

  const updateTracking = (
    keyPath,
    id,
    value,
    round = 0,
    skipAutoSave = false,
  ) => {
    dispatch({
      ...defaultAction,
      type: 'updateTracking',
      keyPath,
      id,
      value,
      round,
      skipAutoSave,
    })
  }

  return updateTracking
}

export const useDeleteScore = () => {
  const { deleteScore } = useContext(TrainingContext)

  return deleteScore
}

export const useResetScore = () => {
  const { dispatch, defaultAction } = useContext(TrainingContext)

  const resetScore = (keyPath, skipAutoSave = true) => {
    dispatch({ ...defaultAction, type: 'resetScore', keyPath, skipAutoSave })
  }

  return resetScore
}

export const useSaveScore = () => {
  const { saveScore, isSavingScore } = useContext(TrainingContext)

  return { saveScore, isSavingScore }
}

export const useSectionNotes = keyPath => {
  const [track, date, kit] = keyPath

  return useSelector(state => state?.[track]?.[date]?.[kit]?.sectionNotes || {})
}

export const useUpdateSectionNotes = () => {
  const { dispatch, defaultAction } = useContext(TrainingContext)

  const updateSectionNotes = (keyPath, value, skipAutoSave = false) => {
    dispatch({
      ...defaultAction,
      type: 'updateSectionNotes',
      keyPath,
      value,
      skipAutoSave,
    })
  }

  return updateSectionNotes
}

export const useMovementSelections = keyPath => {
  const [track, date, kit] = keyPath

  return useSelector(
    state => state?.[track]?.[date]?.[kit]?.movementSelections || {},
  )
}

export const useUpdateMovementSelections = () => {
  const { dispatch, defaultAction } = useContext(TrainingContext)

  const updateMovementSelections = (keyPath, value, skipAutoSave = false) => {
    dispatch({
      ...defaultAction,
      type: 'updateMovementSelections',
      keyPath,
      value,
      skipAutoSave,
    })
  }

  return updateMovementSelections
}
