import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react'

import confetti from 'canvas-confetti'
import 'animate.css'

import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import {
  useWorkshopTraining,
  useWorkshopTrainingJournal,
  useProfile,
  useWorkshops,
  useStatus,
} from '../context/atomContext'

import {
  useUpdateSectionNotes,
  useSectionNotes,
  useUpdateScore,
  useUpdateTracking,
  useMovementSelections,
  useUpdateMovementSelections,
  useScore,
  useSaveScore,
  useDeleteScore,
  useResetScore,
} from '../context/trainingContext'
import { SocialContext } from '../context/socialContext'

import { useIsPermission } from '../hooks/FeatureHooks'
import { useMentions } from '../hooks/TextHooks'

import {
  dateMe,
  setBackgroundImage,
  printClasses,
  getMessageFromError,
  displayTrackName,
  getWorkshop,
  animateCSS,
  kitForID,
  getTrack,
  formattedTime,
  trainingKits,
  urlForTraining,
} from '../Helpers'

import AppLayout from '../components/layout/Layout'

import Button from '../components/elements/Button'
import DayPicker from '../components/elements/DayPicker'
import DetailsBlock from '../components/structures/DetailsBlock'
import Dialog from '../components/elements/Dialog'
import SelectLevel from '../components/molecules/SelectLevel'
import Icon, { PlayIcon } from '../components/elements/Icon'
import IconText from '../components/elements/IconText'
import Markdown from '../components/elements/Markdown'
import Zipper from '../components/elements/Zipper'
import RadioGroup from '../components/inputs/RadioGroup'

import ConfirmDeleteScore from '../components/molecules/ConfirmDeleteScore'
import EditDeleteDialog from '../components/molecules/EditDeleteDialog'
import SelectKit from '../components/molecules/SelectKit'
import FeaturedAthletes from '../components/molecules/FeaturedAthletes'
import FeaturedProducts from '../components/molecules/FeaturedProducts'
import VideoSegments from '../components/molecules/VideoSegments'

import TrainingScore from '../components/structures/TrainingScore'
import UserProfileRow from '../components/structures/UserProfileRow'
import VideoPlayer from '../components/structures/VideoPlayer'

import PerceivedEffort from '../components/molecules/PerceivedEffort'
import SelectByFeel from '../components/molecules/SelectByFeel'

import TrainingJournal from './TrainingJournal'

import {
  displayTitle,
  movementOptionForLevel,
} from './parts-for-training/TrainingHelpers'

import TrainingToolTip from './parts-for-training/TrainingToolTip'
import WorkoutOverlay from './parts-for-training/WorkoutOverlay'
import WorkoutPart from './parts-for-training/WorkoutPart'
import CoachesTrainingDetails from './parts-for-training/CoachesTrainingDetails'
import UserScore from './parts-for-training/UserScore'
import UserTrainingDetails from './parts-for-training/UserTrainingDetails'
import AffiliateMetrics from './parts-for-training/AffiliateMetrics'
import ShowSubmitScore from './parts-for-training/ShowSubmitScore'
import SubmitBar from './parts-for-training/SubmitBar'
import SubmitScoreSplash from '../components/screens/SubmitScoreSplash'

type WorkshopTrainingProps = {
  startDate?: string,
  showCoachesView?: boolean,
}

WorkshopTraining.defaultProps = {
  startDate: undefined,
  showCoachesView: false,
}

function WorkshopTraining(props: WorkshopTrainingProps) {
  const { startDate, showCoachesView } = props
  const { workshopID } = useParams()
  const history = useHistory()
  const queryClient = useQueryClient()

  const isStatsVisible = useIsPermission(['beta', 'statsVisible'])
  const status = useStatus()

  const { data: profileData, isLoading: isProfileLoading } = useProfile()
  const { data: workshops, isLoading: isWorkshopsLoading } = useWorkshops()
  const workshop =
    !isWorkshopsLoading &&
    workshops?.value?.rows?.find(ws => ws.slug === workshopID)

  setBackgroundImage('') // unsets any default bg image hanging around

  useEffect(() => {
    setBackgroundImage('', 'header-bg-image')
  }, [])

  const queryParams = new URLSearchParams(window.location.search)

  const defaultLevel =
    !isProfileLoading && profileData?.value?.level && !showCoachesView
      ? `l${Math.min(4, Math.round(Number(profileData.value.level)))}`
      : 'l1'

  const formatSelection = undefined
  const [date, setDate] = useState(queryParams.get('day') || startDate)
  const [activeKit, setActiveKit] = useState({})
  const [showDemoVideo, setShowDemoVideo] = useState(undefined)
  const [demoVideoData, setDemoVideoData] = useState({})
  const [demoVideoMetaData, setDemoVideoMetaData] = useState(undefined)
  const [showScoreTools, setShowScoreTools] = useState(false)
  const [showConfirmDeleteScore, setShowConfirmDeleteScore] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState(undefined)
  const [showTrainingVideo, setShowTrainingVideo] = useState(false)
  const [skipToTimeCode, setSkipToTimeCode] = useState({ click: 0, time: 0 })
  const [touchPosition, setTouchPosition] = useState(null)
  const [showWorkoutConfig, setShowWorkoutConfig] = useState(false)
  const [track, setTrack] = useState('workshop')
  const [expandJournal, setExpandJournal] = useState(false)
  const [buttonAnimating, setButtonAnimating] = useState(false)

  const socialContext = useContext(SocialContext)
  const { openProfile } = socialContext

  const defaultKit = workshop?.kits?.[0]?.id || 'POWER_KIT'

  const {
    data,
    isFetching,
    isError: isTrainingError,
    error,
  } = useWorkshopTraining(date, workshop?.id, workshop?.id !== undefined)

  const workout = Array.isArray(data?.value?.rows)
    ? data?.value?.rows?.length > 0 && data.value.rows[0]
    : data?.value?.rows

  const {
    data: myTrainingData,
    isFetching: journalIsFetching,
    refetch: refetchTrainingJournal,
    isError: isTrainingJournalError,
  } = useWorkshopTrainingJournal(
    workout?.day,
    workshop?.id,
    null,
    null,
    '-day',
    false,
    'workshop',
    workout?.day !== undefined && !showCoachesView,
  )
  const deleteScore = useDeleteScore()

  const trainingDataForKit = () => {
    const kit = getActiveKitId()
    if (!kit) return
    const filteredTraining = myTrainingData?.value?.rows.filter(
      row => row?.kit === kit,
    )
    if (filteredTraining?.length > 0) return filteredTraining[0]
    return getTrack(track)?.allowAllKitSubmissions !== true
      ? myTrainingData?.value?.rows[0]
      : undefined
  }

  const trainingDates = () =>
    workshop?.user?.completed.map(elem => parseInt(elem, 10)) || []

  const getIcons = () => {
    const icons = {}
    const training = trainingDates()
    for (let i = 0; i < training.length; i += 1) {
      const curDate = training[i]
      const currentDay = icons[curDate] || []
      const containsTraining =
        currentDay.findIndex(elem => elem.name === 'person-running') !== -1
      if (!containsTraining) {
        icons[curDate] = [
          ...currentDay,
          {
            name: 'person-running',
            type: 'fas',
            size: '2xs',
          },
        ]
      }
    }

    return icons
  }

  const training = workout

  const workoutDate = training?.day
  const workoutKits = training?.workout
    ? Object.keys(training.workout).filter(k => k !== 'format')
    : []

  const getActiveKitId = useCallback(() => {
    const kits = training?.workout
      ? Object.keys(training.workout).filter(k => k !== 'format')
      : []

    if (
      track &&
      training?.day &&
      activeKit[track] &&
      activeKit[track][training?.day]
    ) {
      return activeKit[track][training?.day]
    }
    if (kits.length === 0) {
      return defaultKit
    }

    if (kits.includes(defaultKit)) return defaultKit

    return kits[0]
  }, [activeKit, defaultKit, track, training])

  const trainingKeyPath = useMemo(
    () => [workshopID, workoutDate, getActiveKitId()],
    [workshopID, workoutDate, getActiveKitId],
  )
  const updateSectionNotes = useUpdateSectionNotes()
  const sectionNotes = useSectionNotes(trainingKeyPath)
  const updateScore = useUpdateScore()
  const updateTracking = useUpdateTracking()
  const resetScore = useResetScore()
  const { saveScore } = useSaveScore()
  const score = useScore(trainingKeyPath)
  const movementSelections = useMovementSelections(trainingKeyPath)
  const updateMovementSelections = useUpdateMovementSelections()

  const [_showScoreForm, setShowScoreForm] = useState(score.id === undefined)

  const showScoreForm = _showScoreForm || score.status !== 'published'

  const myTraining = trainingDataForKit()
  const mentionUpdate = useCallback(
    val => {
      updateScore([...trainingKeyPath, 'caption'], val)
    },
    [updateScore, trainingKeyPath],
  )
  const mentions = useMentions(
    myTraining?.caption || '',
    'captionInput',
    mentionUpdate,
  )

  const submitThisScore = () => {
    saveScore(
      trainingKeyPath,
      mentions.getPostData(),
      getMovementSelections(),
      computedLevel(),
      scoreTypes,
      (kit, previousStatus) => {
        const buttonSelector = '.training--edit-animated'
        const textSelector = '.training--edit-animated--text'

        queryClient.invalidateQueries(['user', 'me'])

        // no animations for editing scores
        if (previousStatus === 'published') {
          setShowScoreForm(false)
          return
        }
        setButtonAnimating(1)
        animateCSS(buttonSelector, 'bounceOut').then(() => {
          confetti({
            startVelocity: 40,
            angle: 60,
            spread: 360,
            ticks: 125,
            zIndex: 200,
            disableForReducedMotion: true,
            origin: { x: 0, y: 1 },
            particleCount: 200,
            gravity: 0.2,
            colors: ['#edae7e', '#5eb3b4'],
          })
          confetti({
            startVelocity: 40,
            angle: 120,
            spread: 360,
            ticks: 125,
            zIndex: 200,
            disableForReducedMotion: true,
            origin: { x: 1, y: 1 },
            particleCount: 200,
            gravity: 0.2,
            colors: ['#edae7e', '#5eb3b4'],
          })
          setButtonAnimating(2)
          animateCSS(buttonSelector, 'tada').then(() => {
            animateCSS(textSelector, 'fadeOut').then(() => {
              setTimeout(() => {
                setButtonAnimating(false)
                animateCSS(textSelector, 'fadeIn')
                setShowScoreForm(false)
              }, 2500)
            })
          })
        })
      },
    )
  }

  const shakeLogButton = () => {
    const buttonSelector = '.training--edit-animated'
    animateCSS(buttonSelector, ['headShake', 'repeat-2', 'fast'])
  }

  const editOrSubmit = () => {
    // If we are currently animating the button don't actually do anything
    if (buttonAnimating) return
    if (hasMyTrainingInfo && !isDraft && myTraining && !showScoreForm)
      toggleScoreForm()
    else submitThisScore()
  }

  const setAllSelectionsTo = level => {
    const newSelections = {}
    activeWorkout.forEach((workoutPart, partIndex) => {
      if (!newSelections[partIndex]) {
        newSelections[partIndex] = {}
      }

      workoutPart?.movement?.forEach((movement, movementIndex) => {
        const option = movementOptionForLevel(movement, {
          level,
        })
        newSelections[partIndex][movementIndex] = {
          id: option.id,
          level: option.levels[0],
        }
      })
    })

    updateMovementSelections(trainingKeyPath, newSelections, false)
  }

  const isWorkoutAllLevels = () => {
    for (let i = 0; i < activeWorkout?.length; i += 1) {
      const part = activeWorkout[i]
      for (let j = 0; j < part?.movement?.length; j += 1) {
        const movement = part?.movement[j]
        if (movement?.options?.length > 1) return false
      }
    }
    return true
  }

  const selectedKit = training?.workout && training.workout[getActiveKitId()]
  const hasScoredSection = selectedKit?.training.reduce((prev, curr) => {
    if (prev) return prev
    if (curr?.format?.l1?.scoredSection) return true
    if (curr?.isScoredSection) return true
    return false
  }, false)

  const getMediaFeeds = useCallback(
    kitId => {
      const kit = kitId
      const media = workout?.media ? workout?.media : workout?.video
      const kitFeeds = selectedKit?.coaching?.media
      if (showCoachesView && kitFeeds) {
        return kitFeeds
      }
      if (selectedKit?.media) return selectedKit.media
      const feeds =
        media && media[kit] ? [kit] : ['main', 'rabbit1', 'rabbit2', 'rabbit3']
      return feeds
    },
    [selectedKit, showCoachesView, workout],
  )

  const getSelectedVideo = useCallback(() => {
    if (selectedVideo) return selectedVideo
    const feeds = getMediaFeeds(getActiveKitId())
    if (feeds.length) return feeds[0]
    return 'main'
  }, [getActiveKitId, getMediaFeeds, selectedVideo])

  const setTrainingKitSelection = useCallback(
    (aTrack, aDate, newKitSelection) => {
      const trackKits = { ...activeKit[aTrack] }
      trackKits[aDate] = newKitSelection
      const currentVideo = getSelectedVideo()
      const newFeeds = getMediaFeeds(newKitSelection.id)
      if (
        newFeeds.length === 0 ||
        currentVideo !== newFeeds[0] ||
        showCoachesView
      ) {
        setShowTrainingVideo(false)
      }
      setActiveKit({
        ...activeKit,
        [aTrack]: trackKits,
      })
    },
    [activeKit, getMediaFeeds, getSelectedVideo, showCoachesView],
  )

  useEffect(() => {
    if (!journalIsFetching && myTraining === undefined && score.id) {
      // we have no score for today so make sure the store is clear
      resetScore(trainingKeyPath)
      mentions.initializeMentions('')
    }
    if (
      !journalIsFetching &&
      myTraining &&
      (!score.id || score.id !== myTraining.id)
    ) {
      // If a user hasn't made active choices yet, set up their old training
      if (Object.keys(movementSelections).length === 0) {
        updateMovementSelections(trainingKeyPath, myTraining.selections, true)
      }
      setTrainingKitSelection(
        track,
        myTraining.date,
        kitForID(myTraining.kit || defaultKit),
      )
      const scoring = myTraining.training?.workout?.[myTraining.kit].scoring

      let reps = 0
      if (myTraining.score && Object.is(scoring, 'reps')) {
        reps = myTraining.score
      }

      let time = 0
      if (myTraining.score && Object.is(scoring, 'time')) {
        time = formattedTime(myTraining.score)
      }

      let weight = 0
      if (myTraining.score && Object.is(scoring, 'weight')) {
        weight = myTraining.score
      }

      let { beatCap } = myTraining
      if (!Object.keys(myTraining).includes('beatCap')) {
        beatCap = true
      }

      updateScore(
        trainingKeyPath,
        {
          id: myTraining.id,
          trainingId: myTraining.trainingId,
          notes: myTraining.notes,
          caption: myTraining.caption,
          feltLike: myTraining.feltLike,
          perceivedEffort: myTraining.perceivedEffort,
          status: myTraining.status || 'published',
          weight: weight || myTraining.weight,
          reps: reps || myTraining.reps,
          time: time || myTraining.time,
          score: myTraining.score,
          visible: myTraining.visible,
          timecap: score.timecap,
          beatCap,
          tiebreak: myTraining.tiebreak,
          workshopId: myTraining.workshopId,
          day: myTraining.day,
          track: 'workshop',
          scoring,
        },
        true,
      )
      updateTracking(trainingKeyPath, null, myTraining.tracking, null, true)

      mentions.initializeMentions(myTraining.caption)
      if (myTraining.status !== 'draft') {
        // for old training status may be undefined so we can't check for published
        setShowScoreForm(false)
      } // else {
      //   setShowScoreForm(true)
      // }
      if (!sectionNotes || Object.keys(sectionNotes).length === 0) {
        updateSectionNotes(trainingKeyPath, myTraining?.sectionNotes, true)
      }
    }
  }, [
    activeKit,
    defaultKit,
    journalIsFetching,
    movementSelections,
    myTraining,
    setTrainingKitSelection,
    sectionNotes,
    date,
    track,
    updateSectionNotes,
    updateScore,
    updateTracking,
    updateMovementSelections,
    score.id,
    score.timecap,
    mentions,
    resetScore,
    trainingKeyPath,
  ])

  useEffect(() => {
    if (workshopID && workshops?.value?.rows) {
      const myTrack = getWorkshop(workshopID, workshops?.value?.rows)
      if (myTrack.apiRoot !== workshopID) setTrack(myTrack.apiRoot)
    }
  }, [workshopID, workshops])

  const workoutFormat = selectedKit?.format || training?.workout?.format
  const isNewWorkoutVersion = workout?.version === '2.0.0'
  const activeWorkout = selectedKit?.training
  const scoring = isNewWorkoutVersion
    ? training?.workout[getActiveKitId()]?.format?.scoring
    : training?.workout[getActiveKitId()]?.scoring
  const hasLeaderboard = isNewWorkoutVersion
    ? training?.workout[getActiveKitId()]?.format?.hasLeaderboard
    : (training?.workout &&
        training.workout[getActiveKitId()]?.hasLeaderboard) ||
      (training?.workout &&
        training.workout[getActiveKitId()]?.hasLeaderboard === undefined) ||
      (workshop?.hasLeaderboard &&
        training?.workout[getActiveKitId()]?.hasLeaderboard !== false)

  const timecap = workoutFormat && workoutFormat.timecap

  const hasScore = isNewWorkoutVersion
    ? training?.workout[getActiveKitId()]?.format?.hasScore
    : (training?.workout && training.workout[getActiveKitId()]?.hasScore) ||
      (training?.workout &&
        training.workout[getActiveKitId()]?.hasScore === undefined)

  const scoreTypes = [
    scoring?.reps && 'reps',
    scoring?.time && 'time',
    scoring?.weight && 'weight',
  ].filter(Boolean) || ['reps']

  const location = useLocation()

  useEffect(() => {
    if (score.trainingId) {
      return
    }
    const id = data?.value?.rows?.[0].id
    const day = data?.value?.rows?.[0].day
    if (id)
      updateScore(
        trainingKeyPath,
        {
          trainingId: id,
          visible: hasLeaderboard,
          day: day || 1,
          workshopId: workshop.id,
          track: 'workshop',
          timecap,
        },
        true,
      )
  }, [
    data,
    trainingKeyPath,
    score.trainingId,
    hasLeaderboard,
    timecap,
    updateScore,
    workshop,
  ])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const aDate = params.get('day') || undefined

    if (aDate && date !== aDate) {
      setDate(aDate)
      setSelectedVideo(undefined)
    }
  }, [date, location])

  const doSelectKit = item => {
    setTrainingKitSelection(track, training.day, item)
  }

  const selectLevel = (part, movement, level) => {
    const curDate = movementSelections || {}
    const curPart = curDate[part] || {}
    const newPart = { ...curPart, [movement]: level }
    const newDate = { ...curDate, [part]: newPart }

    updateMovementSelections(trainingKeyPath, { ...newDate }, true)
  }

  const getMovementSelections = () => {
    let currentSelections = {}
    currentSelections = JSON.parse(JSON.stringify(movementSelections))

    if (!activeWorkout) {
      return {}
    }

    activeWorkout.forEach((workoutPart, partIndex) => {
      if (!currentSelections[partIndex]) {
        currentSelections[partIndex] = {}
      }
      if (!workoutPart.movement) return

      workoutPart.movement.forEach((movement, movementIndex) => {
        if (!currentSelections[partIndex][movementIndex]) {
          currentSelections[partIndex][movementIndex] = { level: defaultLevel }
        }
      })
    })

    return currentSelections
  }

  const doSelectDate = useCallback(
    aDate => {
      const currentUrlParams = new URLSearchParams(window.location.search)
      setShowTrainingVideo(false)
      setSelectedVideo(undefined)

      currentUrlParams.set('day', aDate)
      setDate(aDate)

      history.push(
        `${history.location.pathname}?${currentUrlParams.toString()}`,
      )
    },
    [history],
  )

  const goToLeaderboard = (kit = null) => {
    let kitFilter = ''

    if (kit) {
      kitFilter = `&kit=${kit}`
    } else {
      kitFilter =
        myTraining && myTraining.kit
          ? `&kit=${myTraining.kit}`
          : `&kit=${getActiveKitId()}`
    }

    history.push(
      `/workshops/${workshop.slug}/leaderboard?day=${workout.day}${
        kitFilter || ''
      }`,
      {
        returnTo: location,
      },
    )
  }

  const computedLevel = () => {
    let myLevel = 0
    let myChoices = 0

    if (activeWorkout) {
      const selections = getMovementSelections()

      activeWorkout.forEach((workoutPart, partIndex) => {
        if (!workoutPart.movement) return
        workoutPart.movement.forEach((movement, movementIndex) => {
          if (movement.type && movement.type !== 'workoutSection') return
          let movementSelection = { level: 'l1' }

          if (selections) {
            movementSelection = selections[partIndex][movementIndex]
          }

          const selectedOption = movementOptionForLevel(
            movement,
            movementSelection,
          )

          if (selectedOption && selectedOption.levels.length !== 4) {
            const thisLevel = parseInt(selectedOption.levels[0].charAt(1), 10)
            myChoices += 1
            myLevel += thisLevel
          }
        })
      })
    }

    if (myLevel === 0) {
      myLevel = 1
    } else {
      myLevel /= myChoices
    }

    return `L${Math.min(4, Math.round(Number(myLevel)))}`
  }

  const toggleScoreForm = () => setShowScoreForm(!showScoreForm)
  const toggleScoreTools = () => setShowScoreTools(!showScoreTools)
  const dismissScoreTools = () => setShowScoreTools(false)
  const toggleWorkoutConfig = () => setShowWorkoutConfig(!showWorkoutConfig)

  const displayEditScoreForm = () => {
    dismissScoreTools()
    toggleScoreForm()
  }

  const displayConfirmDeleteScore = () => {
    setShowConfirmDeleteScore(showScoreTools.id ? showScoreTools : true)
    dismissScoreTools()
  }

  const dismissConfirmDeleteScore = () => setShowConfirmDeleteScore(false)

  const displayDemoVideo = video => {
    setShowDemoVideo(true)
    setDemoVideoData(video)
  }

  useEffect(() => {
    if (showDemoVideo && !expandJournal) {
      const timer = setTimeout(() => {
        setExpandJournal(true)
      }, 1000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [showDemoVideo, expandJournal])

  const dismissDemoVideo = () => {
    setShowDemoVideo(false)
    setDemoVideoData({})
    setDemoVideoMetaData(undefined)
    setExpandJournal(false)
  }

  const headerClasses = [
    'rpm-hero',
    'training--header',
    `${showTrainingVideo ? 'is-playing' : 'is-not-playing'}`,
  ]

  const products = training?.featuredProducts || []
  const workoutMedia = workout?.media ? workout?.media : workout?.video

  const workoutMediaDetails =
    workoutMedia && workoutMedia[getSelectedVideo()]?.custom_fields
  const workoutLocation = workoutMediaDetails?.location || null
  const workoutCoach = workoutMediaDetails?.coaches || null
  const workoutStar = workoutMediaDetails?.star || null
  const workoutTitle =
    workoutFormat?.title || workout?.title || displayTrackName('atomic')
  const workoutTitleDate =
    workoutFormat?.subtitle ||
    workout?.subtitle ||
    dateMe(workout?.day, 'title')
  const videoMetadata = workoutMedia && workoutMedia[getSelectedVideo()]

  const showVideoCarousel = false // && // @NOTE - hiding Carousel for now until we have actual different feeds (per request)
  // workoutMedia &&
  // Object.values(workoutMedia).filter(Boolean).length > 1

  const onCarouselTouchDown = event =>
    setTouchPosition(event.touches[0].clientX)

  const onCarouselTouchMove = event => {
    if (!showVideoCarousel || touchPosition === null) {
      return
    }
    const myFeeds = getMediaFeeds(getActiveKitId())
    const mySelectedVideo = getSelectedVideo()

    const currentTouch = event.touches[0].clientX
    const diff = touchPosition - currentTouch

    if (diff > 5) {
      const index = myFeeds.findIndex(item => item === mySelectedVideo) || 0
      const next = myFeeds[index + 1] || myFeeds[0]
      setSelectedVideo(next)
    }

    if (diff < -5) {
      const index = myFeeds.findIndex(item => item === mySelectedVideo) || 0
      const prev = myFeeds[index - 1] || myFeeds[myFeeds.length - 1]
      setSelectedVideo(prev)
    }

    setTouchPosition(null)
  }

  const updateSocialData = () => {
    refetchTrainingJournal()
  }

  const hasMyTrainingInfo =
    activeWorkout &&
    myTraining &&
    Object.is(parseInt(myTraining.day, 10), parseInt(workout.day, 10)) &&
    (myTraining.score || myTraining.completedOnly || myTraining.tiebreak) &&
    training.workout[myTraining?.kit]?.scoring

  const isDraft = hasMyTrainingInfo && myTraining.status === 'draft'

  const showVideoPlayButton = () =>
    videoMetadata?.id &&
    (Object.is(videoMetadata?.tags, null) ||
      !videoMetadata?.tags?.includes('novideo'))

  const workoutToggle = (
    <div
      className="rpm-block"
      style={{ '--button-fg': 'var(--text-link)', paddingTop: 0 }}
    >
      <Button
        kind="text"
        icon={{ type: 'far', name: 'pen-to-square', size: 'md' }}
        label={showWorkoutConfig ? 'Done' : 'Edit Your Workout'}
        onClick={() => toggleWorkoutConfig()}
        cnames="text--bold text--small text--caps"
      />
    </div>
  )

  const visibleSelectedKit =
    training?.workout?.[hasMyTrainingInfo ? myTraining?.kit : getActiveKitId()]

  const workoutConfig = (isInteractive, isLocked, allowKitSelect) => {
    const localActiveWorkouts = () => {
      const localKit =
        training?.workout?.[
          isLocked && myTraining?.kit ? myTraining?.kit : getActiveKitId()
        ]
      return localKit?.training
    }

    const localActiveWorkout = localActiveWorkouts()
    const dataKits = trainingKits(track, workoutKits)

    const kitOptions = dataKits.map(item => ({
      id: item.id,
      value: item.value,
      label: <IconText icons={item.icons} text={item.label} />,
      checked: false,
    }))

    const editableSelections = movementSelections

    return (
      localActiveWorkout && (
        <>
          {allowKitSelect && showWorkoutConfig && workoutKits?.length > 1 && (
            <RadioGroup
              name="kit"
              label=""
              options={kitOptions}
              handleChange={event => doSelectKit(event.target.value)}
              selectedValue={getActiveKitId()}
              columns={kitOptions.map(() => '1fr').join(' ')}
              cnames="training--kit-select"
            />
          )}

          {!isNewWorkoutVersion && workoutFormat?.title && (
            <h4 className="training--format text--caps">
              {displayTitle(
                workoutFormat,
                formatSelection,
                'format',
                null,
                null,
                Markdown,
              )}
            </h4>
          )}

          {localActiveWorkout.map((workoutPart, index) => (
            <React.Fragment key={String(`${date || myTraining?.day}-${index}`)}>
              <WorkoutPart
                workout={workoutPart}
                selectedItems={
                  isLocked
                    ? myTraining?.selections[index]
                    : editableSelections[index] || {}
                }
                isInteractive={isInteractive || showWorkoutConfig}
                isLocked={isLocked || showCoachesView}
                lockedAction={shakeLogButton}
                defaultLevel={defaultLevel}
                selectLevel={(movement, level) =>
                  selectLevel(index, movement, level)
                }
                setSectionNotes={notes => {
                  updateSectionNotes([...trainingKeyPath, index], notes)
                }}
                sectionNotes={
                  !isLocked
                    ? sectionNotes[index]
                    : myTraining?.sectionNotes?.[index]
                }
                displayEditScoreForm={displayEditScoreForm}
                id={`${date || myTraining?.date}-${index}`}
                showDemo={displayDemoVideo}
                cnames={
                  Object.is(localActiveWorkout.length - 1, index) && 'is-last'
                }
                workoutKeyPath={trainingKeyPath}
                showScoring={!showCoachesView}
                workoutScoring={
                  !showCoachesView &&
                  showScoreForm &&
                  (workoutPart.isScoredSection ||
                    workoutPart.format?.l1?.scoredSection) ? (
                    <ShowSubmitScore
                      visibleSelectedKit={visibleSelectedKit}
                      track={track}
                      date={workoutDate}
                      type={hasScore ? scoreTypes : ['completed']}
                      timecap={workoutFormat?.timecap}
                      hasLeaderboard={hasLeaderboard}
                      mentions={mentions}
                      scoreKeyPath={trainingKeyPath}
                    />
                  ) : null
                }
              />
              {!showCoachesView &&
                !showScoreForm &&
                (workoutPart.isScoredSection ||
                  workoutPart.format?.l1?.scoredSection) && (
                  <UserScore
                    training={hasMyTrainingInfo && !isDraft && myTraining}
                    toggleScoreTools={toggleScoreTools}
                    displayEditScoreForm={displayEditScoreForm}
                    displayTrainingJournal={() => setShowDemoVideo(true)}
                    goToLeaderboard={goToLeaderboard}
                    updateSocialData={updateSocialData}
                    socialContext={socialContext}
                    hasLeaderboard={hasLeaderboard}
                  />
                )}
            </React.Fragment>
          ))}

          {!isInteractive && workoutToggle}
        </>
      )
    )
  }

  const loggedBgColor = showScoreForm ? 'bg--brand-gold' : 'bg--gray'
  const filters = (
    <nav
      id="trainingFilters"
      className="rpm-filters training--filters"
      key="filters"
    >
      <DayPicker
        days={parseInt(workshop?.sessions, 10) || 1}
        mode="day"
        selectedDay={date || 1}
        eventIcons={getIcons()}
        handleDate={doSelectDate}
        config={{
          tools: ['previous', 'next'],
          maxDate: parseInt(workshop?.sessions, 10) || 1,
          minDate: 1,
        }}
      />

      <SelectKit
        item={hasMyTrainingInfo ? myTraining?.kit : getActiveKitId()}
        allowedKits={workoutKits}
        doSelect={doSelectKit}
        track={track}
        isDisabled={track !== 'affiliate' && hasMyTrainingInfo}
        kits={workshop?.kits}
      />

      <Zipper isActive={isFetching} />
      {score?.status === 'published' && !buttonAnimating && (
        <div
          className={printClasses([
            'rpm-block training--editing-status text--center text--small text--caps text--bold grid-column-end-2 fg--light animate__animated animate__slideInDown',
            loggedBgColor,
          ])}
        >
          {showScoreForm ? (
            <IconText
              icon={{ name: 'lock-open', type: 'fas' }}
              text="Editing"
            />
          ) : (
            <IconText icon={{ name: 'lock', type: 'fas' }} text="Logged" />
          )}
        </div>
      )}
    </nav>
  )

  const hero = (
    <React.Fragment key="globalHero">
      {isStatsVisible && (
        <UserProfileRow
          key="userRow"
          user={profileData?.value}
          isMe
          avatarSize={3}
          openProfile={() => openProfile(status?.user?.id)}
          cnames="dashboard--my-user-row training--profile"
          showWorkoutStats
        />
      )}
      <header className={printClasses(headerClasses)} key="hero">
        {activeWorkout && !showTrainingVideo && (
          <>
            {getMediaFeeds(getActiveKitId()).map(key => {
              if (workoutMedia[key]) {
                return (
                  <div
                    key={key}
                    className={printClasses([
                      'poster',
                      Object.is(getSelectedVideo(), key) ? 'active' : '',
                    ])}
                    style={{
                      '--image-url': `url(${workoutMedia[key].poster})`,
                    }}
                  />
                )
              }

              return ''
            })}

            <div
              className="training--header--details"
              onTouchMove={onCarouselTouchMove}
              onTouchStart={event => onCarouselTouchDown(event)}
            >
              {showVideoPlayButton() && (
                <Button
                  cnames="training--header--cover"
                  kind="secret"
                  onClick={() => setShowTrainingVideo(true)}
                />
              )}

              <div className="rpm-hero--info training--header--info text--caps">
                <p className="training--header--location">{workoutLocation}</p>

                <h2 className="training--header--title">{workoutTitle}</h2>

                {workoutTitleDate && (
                  <h3 className="training--header--subtitle">
                    {workoutTitleDate}
                  </h3>
                )}

                {workoutCoach && (
                  <p className="training--header--coach">
                    <IconText
                      icon={{ name: 'coach', type: 'svg' }}
                      text={workoutCoach}
                    />
                  </p>
                )}

                {workoutStar && (
                  <p className="training--header--star">
                    <IconText
                      icon={{ name: 'star', type: 'svg' }}
                      text={workoutStar}
                    />
                  </p>
                )}
              </div>

              {showVideoPlayButton() && (
                <div className="rpm-hero--cta training--header--video-cue">
                  <Button
                    kind="text"
                    onClick={() => setShowTrainingVideo(true)}
                  >
                    <PlayIcon />
                  </Button>
                </div>
              )}

              {hasMyTrainingInfo && !isDraft && (
                <TrainingScore data={myTraining} isLight showHealthEnergy />
              )}
            </div>

            {showVideoCarousel && (
              <div className="training--header--carousel">
                {getMediaFeeds(getActiveKitId()).map(key => {
                  if (workoutMedia && workoutMedia[key]) {
                    return (
                      <Button
                        key={key}
                        kind="text"
                        cnames={getSelectedVideo() === key ? 'is-selected' : ''}
                        onClick={() => {
                          setSelectedVideo(key)
                        }}
                      >
                        <Icon type="fas" name="circle" />
                      </Button>
                    )
                  }

                  return ''
                })}
              </div>
            )}
          </>
        )}

        {visibleSelectedKit?.training && showTrainingVideo && (
          <VideoPlayer
            videoId={
              workoutMedia[getSelectedVideo()].id || workoutMedia?.main.id
            }
            key={workoutMedia[getSelectedVideo()].id || workoutMedia?.main.id}
            skipToTimeCode={skipToTimeCode}
            autoPlay
            showWorkoutOverlay={!showCoachesView}
            workout={
              <WorkoutOverlay
                activeWorkout={visibleSelectedKit.training}
                workoutFormat={workoutFormat}
                defaultLevel={defaultLevel}
                movementSelections={
                  myTraining?.selections || movementSelections
                }
              />
            }
            quality="high"
          />
        )}
      </header>
    </React.Fragment>
  )

  const isEditMode = hasMyTrainingInfo && !isDraft && myTraining
  const workoutButtonLabel =
    isEditMode && !showScoreForm ? 'Edit Workout' : 'Log Workout'
  const isPublished =
    isEditMode && showScoreForm && score.status === 'published'
  const logButtonClasses = isPublished
    ? 'rpm-button-block is-shaded training--log-buttons training--log-buttons--edit'
    : 'rpm-button-block is-shaded training--log-buttons'

  const saveButtonLabel = () => {
    if (buttonAnimating === 1) return 'Log Workout'
    if (buttonAnimating === 2) return 'Nice Work!'
    if (isPublished) return 'Save Workout'
    return workoutButtonLabel
  }

  const heels = (
    <>
      <SubmitScoreSplash visible={buttonAnimating !== false} />
      {activeWorkout && (
        <SubmitBar
          openLogbook={() => setShowDemoVideo(true)}
          toggleScoreForm={toggleScoreForm}
          saveButtonLabel={saveButtonLabel()}
          saveButtonAction={editOrSubmit}
          displayConfirmDeleteScore={displayConfirmDeleteScore}
          goToLeaderboard={() => goToLeaderboard()}
          showLogButtons={!showScoreForm || !isPublished || buttonAnimating}
          showEditButtons={isPublished && !buttonAnimating}
          cnames={`${logButtonClasses} training--log-buttons--full-width`}
        />
      )}

      {!showScoreTools && !showConfirmDeleteScore && (
        <Dialog
          isOpen={showDemoVideo}
          handleDismiss={dismissDemoVideo}
          header={
            demoVideoMetaData?.name ||
            demoVideoData.demoVideo?.title ||
            demoVideoData.title ||
            'Training Journal'
          }
          cnames="training--dialog--demo-video ui-dark"
        >
          {demoVideoData?.demoVideoId && (
            <VideoPlayer
              videoId={demoVideoData?.demoVideoId}
              showWorkoutOverlay={false}
              resume={false}
              autoPlay
              muted
              onMetaDataLoad={
                demoVideoData?.demoVideoId?.toString().slice(0, 5) !== 'atom.'
                  ? player => {
                      setDemoVideoMetaData(player?.mediainfo)
                    }
                  : undefined
              }
            />
          )}

          <div className="training--journal-embed">
            {(expandJournal || demoVideoData?.demoVideoId === undefined) && (
              <TrainingJournal
                kind="component"
                searchTerm={
                  demoVideoMetaData?.name ||
                  demoVideoData.demoVideo?.title ||
                  demoVideoData.title
                }
                key={
                  demoVideoMetaData?.name ||
                  demoVideoData.demoVideo?.title ||
                  demoVideoData.title
                }
                dialogs={{
                  setShowScoreTools: row => {
                    if (row) history.push(urlForTraining(row))
                    dismissDemoVideo()
                  },
                }}
              />
            )}
            {demoVideoData?.demoVideoId && (
              <Button
                kind="text"
                onClick={() => setExpandJournal(!expandJournal)}
                cnames="button--expand-journal text--small text--bold text--caps"
              >
                {expandJournal ? (
                  <IconText icon={{ name: 'chevron-up', type: 'fal' }} />
                ) : (
                  <IconText
                    icon={{ name: 'chevron-down', type: 'fal' }}
                    text="Training Journal"
                  />
                )}
              </Button>
            )}
          </div>
        </Dialog>
      )}

      <EditDeleteDialog
        isOpen={showScoreTools}
        dismiss={dismissScoreTools}
        displayConfirmDeleteScore={displayConfirmDeleteScore}
        displayEditScoreForm={() => {
          if (showScoreTools.id) {
            // history.push(urlForTraining(showScoreTools))
            dismissScoreTools()
            dismissDemoVideo()
          } else {
            displayEditScoreForm()
          }
        }}
      />

      <ConfirmDeleteScore
        isOpen={showConfirmDeleteScore}
        action={() => {
          if (showConfirmDeleteScore.id && showConfirmDeleteScore.track) {
            // const objectToDelete = {
            //   id: showConfirmDeleteScore.id,
            //   track: showConfirmDeleteScore.track,
            // }
            // deleteScore(undefined, objectToDelete)
          } else {
            deleteScore(trainingKeyPath)
          }
          dismissConfirmDeleteScore()
        }}
        dismissAction={dismissConfirmDeleteScore}
      />
    </>
  )

  return (
    <AppLayout
      name="training"
      headerConfig={
        showCoachesView
          ? { label: 'Session Plan' }
          : { label: displayTrackName(track) }
      }
      hero={hero}
      filters={filters}
      heels={heels}
      aside={
        !showCoachesView && (
          <FeaturedProducts cnames="training--products" products={products} />
        )
      }
      isLoading={isFetching || journalIsFetching}
      isError={isTrainingJournalError || isTrainingError}
      errorMessage={getMessageFromError(error)}
      errorAction={() => window.location.reload()}
    >
      <section className="training--workouts">
        {!isFetching && !activeWorkout && (
          <div className="rpm-description rpm-description--fallback training--fallback">
            <p>No Workout Programmed</p>
          </div>
        )}

        {activeWorkout && (
          <>
            <AffiliateMetrics metric={visibleSelectedKit} />

            {(workoutFormat?.description || workout?.description) && (
              <DetailsBlock
                collapsible
                lines="4"
                cnames="rpm-description training--description"
              >
                <Markdown>
                  {workoutFormat?.description || workout.description}
                </Markdown>
              </DetailsBlock>
            )}

            {workoutConfig(true, !showScoreForm, false)}

            <div className="rpm-header training--leaderboard text--caps">
              <b className="training--timecap">
                {workoutFormat?.timecap > 0 && (
                  <small>{workoutFormat?.timecap} min cap</small>
                )}
              </b>

              {(!showScoreForm || isWorkoutAllLevels()) && (
                <b>
                  <small>Level</small>
                </b>
              )}
              {showScoreForm && !isWorkoutAllLevels() && (
                <SelectLevel
                  item={{}}
                  doSelect={elem => setAllSelectionsTo(elem?.id)}
                  isDisabled={!showScoreForm}
                />
              )}

              <TrainingToolTip
                tooltip={
                  <p>
                    This is the overall difficulty level of your workout as it’s
                    currently configured based on the average of the movements
                    selected. Your exact “Scaling Score” will be shown after
                    logging your workout. Note: Movements that are the same
                    across all levels do not affect your average.
                  </p>
                }
                buttonStyles={{
                  minWidth: 'unset',
                  '--button-cursor': 'help',
                }}
              >
                <Icon type="svg" name={computedLevel()} size="lg" />
              </TrainingToolTip>
            </div>

            {!showCoachesView && showScoreForm && (
              <div className="rpm-submit-score">
                {!hasScoredSection && (
                  <ShowSubmitScore
                    visibleSelectedKit={visibleSelectedKit}
                    track={track}
                    date={workoutDate}
                    type={hasScore ? scoreTypes : ['completed']}
                    timecap={workoutFormat?.timecap}
                    hasLeaderboard={hasLeaderboard}
                    mentions={mentions}
                    scoreKeyPath={trainingKeyPath}
                  />
                )}
              </div>
            )}

            {!showCoachesView && !showScoreForm && !hasScoredSection && (
              <UserScore
                training={hasMyTrainingInfo && !isDraft && myTraining}
                toggleScoreTools={toggleScoreTools}
                displayEditScoreForm={displayEditScoreForm}
                displayTrainingJournal={() => setShowDemoVideo(true)}
                goToLeaderboard={goToLeaderboard}
                updateSocialData={updateSocialData}
                socialContext={socialContext}
                hasLeaderboard={hasLeaderboard}
              />
            )}

            {!showCoachesView && (
              <div className="rpm-submit-score">
                <section
                  className={`rpm-description rpm-dialog--section rpm-submit-score--perceived ${
                    !showScoreForm ? 'disabled' : ''
                  }`}
                >
                  <PerceivedEffort
                    value={
                      showScoreForm
                        ? score?.perceivedEffort
                        : myTraining?.perceivedEffort
                    }
                    onChange={val => {
                      if (!showScoreForm) {
                        shakeLogButton()
                        return
                      }
                      updateScore([...trainingKeyPath, 'perceivedEffort'], val)
                    }}
                    disabled={!showScoreForm}
                  />
                </section>

                <section
                  className={`rpm-description rpm-dialog--section rpm-submit-score--feltLike ${
                    !showScoreForm ? 'disabled' : ''
                  }`}
                >
                  <SelectByFeel
                    value={
                      showScoreForm ? score?.feltLike : myTraining?.feltLike
                    }
                    onChange={val => {
                      if (!showScoreForm) {
                        shakeLogButton()
                        return
                      }
                      updateScore([...trainingKeyPath, 'feltLike'], val)
                    }}
                    disabled={!showScoreForm}
                  />
                </section>
              </div>
            )}

            {showCoachesView ? (
              <CoachesTrainingDetails
                visibleSelectedKit={visibleSelectedKit}
                downloadUrl={workout?.downloadUrl}
              />
            ) : (
              <UserTrainingDetails
                visibleSelectedKit={visibleSelectedKit}
                track={getTrack(track)}
              />
            )}
          </>
        )}

        {activeWorkout && (
          <SubmitBar
            openLogbook={() => setShowDemoVideo(true)}
            toggleScoreForm={toggleScoreForm}
            saveButtonLabel={saveButtonLabel()}
            saveButtonAction={editOrSubmit}
            displayConfirmDeleteScore={displayConfirmDeleteScore}
            goToLeaderboard={() => goToLeaderboard()}
            showLogButtons={!showScoreForm || !isPublished || buttonAnimating}
            showEditButtons={isPublished && !buttonAnimating}
            cnames={`${logButtonClasses} training--log-buttons--one-column`}
          />
        )}
      </section>

      {!showCoachesView && showVideoPlayButton() && (
        <VideoSegments
          videoMetadata={videoMetadata}
          setSkipToTimeCode={setSkipToTimeCode}
          showTrainingVideo={showTrainingVideo}
          setShowTrainingVideo={setShowTrainingVideo}
        />
      )}

      {!showCoachesView && Object.is(track, 'atomic') && (
        <FeaturedAthletes
          date={date || training?.day}
          updateSocialData={updateSocialData}
          socialContext={socialContext}
          track={track}
        />
      )}
    </AppLayout>
  )
}

export default WorkshopTraining
