import React, { createContext, useState, useEffect, useContext } from 'react'
import {
  useQueryClient,
  useQuery,
  useInfiniteQuery,
  useMutation,
} from 'react-query'
import axios from 'axios'

import { sendEvent } from '../Helpers'

export const AtomContext = createContext()

type Props = {
  children: object,
}

// FOR TESTING PURPOSES ONLY
// axios.interceptors.request.use(
//   async config => {
//     // Add a Sleep delay to every request
//     const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
//     await sleep(3000)
//     return config
//   },
//   error =>
//     // Do something with request error
//     Promise.reject(error),
// )

// axios.interceptors.response.use(
//   response => {
//     // fail a leaderboard query 50% of the time
//     // if (response.config.url === 'leaderboard' && Math.random() > 0.5) {
//     //   response.data.status = 401
//     // }
//     console.log("search for this string")
//     return response
//   },
//   error =>
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     Promise.reject(error),
// )

/**
 * Handles different error codes and performs appropriate actions such as redirection or throwing errors.
 *
 * @param {number} code - The error code to handle.
 * @param {string} url - The URL associated with the error.
 *
 * @throws {Error} Throws an error if the code is 460 indicating no active subscription.
 */
const handleIfError = (code, url) => {
  switch (code) {
    case 401:
    case 403:
      if (url !== 'user/login' && url !== 'user/login/link/authenticate') {
        // only redirect in the case we aren't already on the login page
        if (window.location !== '/login') {
          const loginExtras =
            typeof window !== 'undefined'
              ? `&onSuccess=${window?.location?.protocol}//${
                  window?.location?.hostname
                }${window?.location?.port ? `:${window?.location?.port}` : ''}`
              : ''

          window.location = `/api/authenticate/login?view=login${loginExtras}`
        }
      }
      break
    case 460: {
      // No active subscription for this content
      const e = Error('This content requires an active subscription.')
      e.name = 'ErrorNoSubscription'
      throw e
    }
    case 503:
      window.location = '/app/maintenance.html'
      break
    default:
  }
}

// Add a response interceptor to test login
axios.interceptors.response.use(
  response => {
    handleIfError(response.data.status, response.config.url)
    return response
  },
  error => {
    handleIfError(error.status, error.config.url)
    Promise.reject(error)
  },
)

/**
 * AtomProvider component that provides context values to its children.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the provider.
 *
 * @returns {JSX.Element} The AtomContext provider with the context values.
 *
 * @example
 * <AtomProvider>
 *   <YourComponent />
 * </AtomProvider>
 *
 * @typedef {Object} Props
 * @property {React.ReactNode} children - The child components to be wrapped by the provider.
 *
 * @typedef {Object} ContextValue
 * @property {Object} status - The status data fetched from the API.
 * @property {boolean} isStatusLoading - Indicates if the status data is still loading.
 * @property {boolean} isDemoMode - Indicates if the application is in demo mode.
 * @property {string} version - The current version of the application.
 * @property {boolean} needsRestart - Indicates if the application needs to be restarted.
 */
const AtomProvider = ({ children }: Props) => {
  const isDemoMode = process.env.REACT_APP_DEMO_MODE === '1'
  const version = process.env.REACT_APP_VERSION
  const [needsRestart, setNeedsRestart] = useState(false)
  const isDebug = ['development', 'test'].includes(process.env.NODE_ENV)

  /**
   * Fetches configuration data using a React Query hook.
   *
   * @returns {Object} An object containing the configuration data and loading state.
   * @property {Object} data - The configuration data fetched from the server.
   * @property {boolean} isLoading - Indicates whether the data is currently being loaded.
   *
   * @function useQuery
   * @param {Array} queryKey - The unique key for the query.
   * @param {Function} queryFn - The function that fetches the data.
   * @param {Object} options - The options for the query.
   * @param {number} options.staleTime - The time in milliseconds before the data is considered stale.
   * @param {number} options.refetchInterval - The interval in milliseconds for refetching the data.
   * @param {boolean} options.enabled - Indicates whether the query should be enabled.
   */
  const { data: versionData, isLoading } = useQuery(
    ['cfgData'],
    async () => {
      const baseAxios = axios.create({})
      baseAxios.defaults.baseURL = '/'
      const { data } = await baseAxios.get(`/app/cfgData.json?${Date.now()}`, {
        params: {},
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return data
    },
    {
      staleTime: 5000,
      refetchInterval: 60 * 1000 * 60,
      enabled: !isDebug,
    },
  )

  /**
   * Fetches the status data using the `useQuery` hook.
   *
   * @returns {Object} An object containing the status data and loading state.
   * @property {Object} data - The status data.
   * @property {boolean} isLoading - The loading state of the status data.
   */
  const { data: status, isLoading: isStatusLoading } = useQuery(
    ['status'],
    async () => {
      const baseAxios = axios.create({})
      baseAxios.defaults.baseURL = '/api'
      const { data } = await baseAxios.get('authenticate/status', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return data
    },
    {
      staleTime: 60000,
    },
  )

  useEffect(() => {
    if (isLoading) return
    if (versionData?.version !== version) setNeedsRestart(true)
    if (versionData?.version === version) setNeedsRestart(false)
  }, [isLoading, version, versionData])

  return (
    <AtomContext.Provider
      value={{
        status,
        isStatusLoading,
        isDemoMode,
        version,
        needsRestart,
      }}
    >
      {children}
    </AtomContext.Provider>
  )
}

export function useStatus() {
  const context = useContext(AtomContext)

  return context.status
}

/**
 * Custom hook to fetch affiliate overview data.
 *
 * @param {string} date - The date for which to fetch the overview.
 * @param {string} kit - The kit identifier for the overview.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook.
 *
 * @example
 * const { data, error, isLoading } = useAffiliateOverview('2023-10-01', 'POWER_KIT', true);
 */
export function useAffiliateOverview(date, kit, enabled) {
  return useQuery(
    ['affiliate', 'overview', date, kit],
    async ({ queryKey }) => {
      const [, , overviewDate, overviewKit] = queryKey

      const params = {
        date: overviewDate,
        kit: overviewKit,
      }

      const { data } = await axios.get(`/affiliate/overview`, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Affiliate Overview data.')

      return data
    },
    {
      enabled,
      keepPreviousData: true,
      staleTime: 60000,
    },
  )
}

/**
 * Custom hook to fetch the affiliate data for the current user.
 *
 * @param {boolean} [enabled=true] - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook containing the affiliate data.
 *
 * @throws {Error} - Throws an error if unable to fetch user profile data.
 */
export function useMyAffiliate(enabled: boolean = true) {
  const context = useContext(AtomContext)
  const enabledState = enabled !== undefined ? enabled : true

  return useQuery(
    ['affiliate', 'myAffiliate'],
    async () => {
      if (context?.profile && !context?.profile?.username) {
        return {}
      }
      const { data } = await axios.get('affiliate/user/myaffiliate', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch User Profile data.')
      return data
    },
    {
      staleTime: 60000,
      enabled: enabledState,
    },
  )
}

/**
 * Custom hook to set the affiliate profile using a mutation.
 *
 * This hook uses `useMutation` from `react-query` to post affiliate profile data to the server.
 * It handles different response statuses and throws appropriate errors if needed.
 * On successful mutation, it sends an event and invalidates relevant queries.
 *
 * @returns {MutationFunction} A mutation function to set the affiliate profile.
 *
 * @throws {FormError} If the response status is 422, indicating a validation error.
 * @throws {Error} If the response status is not 200, indicating a general error.
 */
export function useSetAffiliateProfile() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('affiliate/update', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (results.data.status === 422) {
        throw new FormError(
          'Error posting affiliate profile.',
          results?.data?.value,
        )
      }

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Affiliate-Profile-Set')
        queryClient.invalidateQueries(['affiliate', 'myAffiliate'])
      },
    },
  )
}

/**
 * Custom hook to update an affiliate user.
 *
 * This hook uses `useMutation` from `react-query` to handle the mutation of updating an affiliate user.
 * It sends a POST request to the 'affiliate/user/update' endpoint with the provided data.
 * On success, it triggers an event and invalidates the relevant queries to ensure the data is up-to-date.
 *
 * @returns {MutationFunction} A mutation function that can be used to trigger the update.
 *
 * @throws {FormError} If the response status is 422, indicating a validation error.
 * @throws {Error} If the response status is not 200, indicating a general error.
 */
export function useUpdateAffiliateUser() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('affiliate/user/update', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (results.data.status === 422) {
        throw new FormError(
          'Error Updating Affilliate User.',
          results?.data?.value,
        )
      }

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Affiliate-User-Edit')
        queryClient.invalidateQueries(['affiliate', 'myAffiliate'])
      },
    },
  )
}

/**
 * Custom hook to delete an affiliate user.
 *
 * This hook uses the `useMutation` hook from `react-query` to handle the deletion
 * of an affiliate user via an HTTP DELETE request. It also handles query invalidation
 * and event sending upon successful deletion.
 *
 * @returns {Mutation} A mutation object from `react-query` to handle the deletion process.
 *
 * @example
 * const mutation = useDeleteAffiliateUser();
 * mutation.mutate(postData);
 */
export function useDeleteAffiliateUser() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.delete('affiliate/user/delete', {
        data: postData,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status === 422) {
        throw new FormError(
          'Error Deleting Affilliate User.',
          results?.data?.value,
        )
      }

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Affiliate-User-Delete')
        queryClient.invalidateQueries(['affiliate', 'myAffiliate'])
      },
    },
  )
}

/**
 * Custom hook to invite affiliate users.
 *
 * This hook uses a mutation to send a POST request to invite affiliate users.
 * It expects the data to be sent in the request body and handles the response.
 * If the response status is not 200, it throws an error.
 *
 * @returns {Mutation} A mutation object that can be used to trigger the invite action.
 *
 * @example
 * const { mutate } = useInviteAffiliateUsers();
 * mutate(postData);
 */
export function useInviteAffiliateUsers() {
  // const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('affiliate/user/invite', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw Error('Error Inviting to Team.')
      }

      return results
    },
    {
      onSuccess: response => {
        console.log('response', response)
        // queryClient.invalidateQueries([
        //   'event',
        //   'team',
        //   response.data?.value?.teamId,
        // ])
        // queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

/**
 * Custom hook to fetch training data using a query.
 *
 * @param {string} date - The date for which to fetch training data.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @param {string} track - The training track to fetch data for.
 * @param {Array} [options=[]] - Additional options for the query.
 * @returns {Object} - The result of the query.
 */
export function useTraining(date, enabled, track, options = []) {
  return useQuery(
    ['training', track || 'atomic', date, options],
    async ({ queryKey }) => {
      // const json = localStorage.getItem('config')
      // const savedConfig = JSON.parse(json)

      const [, trainingTrack, trainingDate, trainingOptions] = queryKey

      const params = {
        date: trainingDate,
        ...trainingOptions,
      }

      const { data } = await axios.get(`${trainingTrack}/training/view`, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Training data.')

      return data
    },
    {
      enabled,
      keepPreviousData: true,
      staleTime: 60000,
    },
  )
}

/**
 * Custom hook to fetch training journal data using React Query.
 *
 * @param {string} date - The date for which to fetch the training journal.
 * @param {number} page - The current page number.
 * @param {number} perPage - The number of items per page.
 * @param {string} sort - The sorting order.
 * @param {string} training - The training type.
 * @param {string} track - The track type.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @param {Object} searchData - The search data object containing various search parameters.
 * @param {string} searchData.search - General search term.
 * @param {string} searchData.notesSearchTerm - Search term for notes.
 * @param {string} searchData.fromSearchDate - Start date for the search range.
 * @param {string} searchData.toSearchDate - End date for the search range.
 * @param {string} searchData.movementSearchTerm - Search term for movements.
 * @param {Function} onSuccess - Callback function to be called on successful data fetch.
 * @returns {Object} - The result of the useQuery hook.
 */
export function useTrainingJournal(
  date,
  page,
  perPage,
  sort,
  training,
  track,
  enabled,
  searchData,
  onSuccess,
) {
  return useQuery(
    [
      'training_journal',
      track || 'atomic',
      date,
      searchData,
      page,
      perPage,
      sort,
      training,
    ],
    async ({ queryKey }) => {
      const [
        ,
        trainingTrack,
        apiDate,
        apiSearchData,
        apiPage,
        apiPerPage,
        apiSort,
        apiTraining,
      ] = queryKey

      const {
        search,
        notesSearchTerm,
        fromSearchDate,
        toSearchDate,
        movementSearchTerm,
      } = apiSearchData || {}

      const options = {
        date: apiDate,
        page: apiPage,
        'per-page': apiPerPage,
        sort: apiSort,
        training: apiTraining,
        search,
        notesSearchTerm,
        movementSearchTerm,
        fromSearchDate,
        toSearchDate,
      }

      const params = Object.keys(options).reduce((lastObj, key) => {
        const value = options[key]
        const newObj = { ...lastObj }

        if (value !== null && value !== '') {
          newObj[key] = value
        }

        return newObj
      }, {})

      let url = 'user/journal'
      if (track !== 'journal') {
        url = `${trainingTrack}/user/training`
      }

      const { data } = await axios.get(url, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Training data.')

      return data
    },
    {
      enabled,
      staleTime: 60000,
      keepPreviousData: true,
      onSuccess,
    },
  )
}

/**
 * Custom hook to fetch the training calendar for a given year and month.
 *
 * @param {string} yearMonth - The year and month in the format 'YYYY-MM' to fetch the training calendar for.
 * @returns {object} - The result of the useQuery hook, which includes the training calendar data.
 *
 * @example
 * const { data, error, isLoading } = useTrainingCalendar('2023-10');
 *
 * @throws {Error} - Throws an error if the training calendar data cannot be fetched.
 */
export function useTrainingCalendar(yearMonth) {
  return useQuery(
    ['training_calendar', yearMonth],
    async ({ queryKey }) => {
      const [, apiYearMonth] = queryKey
      if (!apiYearMonth) return {}

      const { data } = await axios.get(`calendar/${apiYearMonth}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Error downloading training calendar.')

      return data
    },
    {
      staleTime: 60000,
    },
  )
}

/**
 * Custom hook to fetch promotions data using react-query's useQuery.
 *
 * @param {Object} initialPromotion - The initial promotion data to be used as placeholder.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @returns {Object} - The result of the useQuery hook, including the promotions data and query status.
 */
export function usePromotions(initialPromotion, enabled) {
  return useQuery(
    ['promotions'],
    async () => {
      const { data } = await axios.get('promotion', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Error downloading promotions.')

      return data
    },
    {
      enabled,
      placeholderData: initialPromotion,
      staleTime: 60000 * 10,
    },
  )
}

/**
 * Custom hook to fetch activities using the `useQuery` hook.
 *
 * @param {Function} onSuccess - Callback function to be called on successful data fetch.
 * @returns {Object} - The result of the `useQuery` hook.
 *
 * @example
 * const { data, error, isLoading } = useActivities((result) => {
 *   console.log('Activities fetched successfully:', result);
 * });
 */
export function useActivities(onSuccess) {
  return useQuery(
    ['activities'],
    async () => {
      const { data } = await axios.get('activity/feed', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Error downloading activities.')

      return data
    },
    {
      refetchOnMount: 'always',
      onSuccess: result => {
        if (onSuccess) onSuccess(result)
      },
      staleTime: 60000 * 10,
    },
  )
}

/**
 * Custom hook to mark activities as read.
 *
 * This hook uses a mutation to send a POST request to the 'activity/viewed' endpoint
 * with the provided postData. If the request is successful, it invalidates the 'activities'
 * query in the query client to ensure the data is refreshed.
 *
 * @returns {Mutation} A mutation object that can be used to trigger the mutation.
 */
export function useMarkActivitiesRead() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      if (!postData) return

      const results = await axios.post('activity/viewed', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries(['activities'])
        console.log(response)
      },
    },
  )
}

/**
 * Custom hook to fetch comments using a query.
 *
 * @param {string} commentId - The ID of the comment to fetch.
 * @param {string} [track='atomic'] - The track to fetch comments from.
 * @param {string} [sort='-created'] - The sorting order of the comments.
 * @param {Object} [config] - Additional configuration options.
 
 * The following two options are used to customize the comment fetching (certain endpoints may use different parameter names):
 * @param {string} [config.commentIdName='userTrainingId'] - The name of the comment ID parameter.
 * @param {string} [config.apiPath='/user/training/comment'] - The API path to fetch comments from.
 
 * @returns {Object} The result of the query, including the fetched comments.
 */
export function useComments(
  commentId,
  track = 'atomic',
  sort = '-created',
  config,
) {
  return useQuery(
    ['user', 'comments', commentId, track, sort, config],
    async () => {
      if (!commentId || !track) return {}
      const params = {
        [config?.commentIdName || 'userTrainingId']: commentId,
        sort,
      }
      const apiPath = config?.apiPath || '/user/training/comment'
      const { data } = await axios.get(`${track}${apiPath}`, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Error downloading comments.')

      return data
    },
    {
      staleTime: 60000,
    },
  )
}

/**
 * Custom hook to set a comment using a mutation.
 *
 * @param {Object} config - Configuration object.
 
 * The following two options are used to customize the comment fetching (certain endpoints may use different parameter names):
 * @param {string} [config.apiPath='/user/training/comment'] - API path for posting the comment.
 * @param {string} [config.commentIdName='userTrainingId'] - Name of the comment ID field in the response data.
 
 * @returns {Mutation} - A mutation object to handle the comment posting.
 *
 * @example
 * const { mutate } = useSetComment({ apiPath: '/custom/path', commentIdName: 'customId' });
 * mutate({ track: 'customTrack', comment: 'This is a comment' });
 */
export function useSetComment(config) {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const track = postData?.track || 'atomic'
      const apiPath = config?.apiPath || '/user/training/comment'

      const results = await axios.post(`${track}${apiPath}`, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw Error('Error posting comment.')
      }

      return results
    },
    {
      onSuccess: (response, vars) => {
        sendEvent('ATOM-Comment-Posted')
        queryClient.invalidateQueries([
          'user',
          'comments',
          response.data.value[config?.commentIdName || 'userTrainingId'],
          vars?.track || 'atomic',
        ])
      },
    },
  )
}

/**
 * Custom hook to delete a comment using a mutation.
 *
 * @param {Object} config - Configuration object.
 * @param {string} [config.apiPath='/user/training/comment'] - API path for the delete request.
 * @returns {Object} - Mutation object from useMutation.
 *
 * @example
 * const { mutate: deleteComment } = useDeleteComment({ apiPath: '/custom/path' });
 * deleteComment({ id: 'commentId', track: 'trackId', threadId: 'threadId' });
 */
export function useDeleteComment(config) {
  const queryClient = useQueryClient()

  return useMutation(
    async vars => {
      if (!vars?.id || !vars?.track) return {}
      const apiPath = config?.apiPath || '/user/training/comment'

      const results = await axios.delete(`${vars.track}${apiPath}`, {
        data: {
          id: vars.id,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: (response, vars) => {
        if (!vars?.threadId) return
        sendEvent('ATOM-Comment-Deleted')
        queryClient.invalidateQueries([
          'user',
          'comments',
          vars.threadId,
          vars.track,
        ])
      },
    },
  )
}

/**
 * Custom hook to toggle the like status of a training post.
 *
 * @param {Object} config - Configuration object.
 * @param {string} [config.track] - The track to be used in the API path.
 * @param {string} [config.apiPath] - The API path for the like request.
 * @returns {MutationFunction} - A mutation function to toggle the like status.
 *
 * @example
 * const toggleLike = useToggleLike({ track: 'customTrack', apiPath: '/custom/path' });
 * toggleLike({ postId: 123 });
 */
export function useToggleLike(config) {
  return useMutation(
    async postData => {
      const track = config?.track || postData?.track || 'atomic'
      const apiPath = config?.apiPath || '/user/training/like'

      const results = await axios.post(`${track}${apiPath}`, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Training-Like')
      },
    },
  )
}

/**
 * Custom hook to fetch user subscriptions.
 *
 * @param {boolean} [enabled=true] - Flag to enable or disable the query.
 * @returns {object} - The result of the `useQuery` hook, including the subscription data.
 */
export function useSubscriptions(enabled: boolean = true) {
  const enabledState = enabled !== undefined ? enabled : true

  return useQuery(
    ['user', 'subscriptions'],
    async () => {
      const { data } = await axios.get('user/subscriptions', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return data
    },
    {
      staleTime: 60000,
      enabled: enabledState,
    },
  )
}

/**
 * Custom hook to fetch user profile data.
 *
 * @param {boolean} [enabled=true] - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook, including the user profile data.
 */
export function useProfile(enabled: boolean = true) {
  const enabledState = enabled !== undefined ? enabled : true

  return useQuery(
    ['user', 'me'],
    async () => {
      const { data } = await axios.get('user/profile', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return data
    },
    {
      staleTime: 60000,
      enabled: enabledState,
    },
  )
}

/**
 * Custom hook to set the user profile using a mutation.
 *
 * This hook uses `useMutation` to post user profile data to the server.
 * On success, it updates the query cache and invalidates relevant queries.
 *
 * @returns {MutationFunction} A mutation function to post user profile data.
 *
 * @throws {FormError} If the server responds with a status of 422.
 * @throws {Error} If the server responds with a status other than 200.
 */
export function useSetProfile() {
  const queryClient = useQueryClient()
  const context = useContext(AtomContext)

  return useMutation(
    async postData => {
      const results = await axios.post('user/profile', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (results.data.status === 422) {
        throw new FormError('Error posting profile.', results?.data?.value)
      }

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: response => {
        sendEvent('ATOM-Profile-Set')
        queryClient.setQueryData(['user', 'me'], response.data)
        queryClient.invalidateQueries(['user', context?.status?.user?.id])
        queryClient.invalidateQueries(['status'])
      },
    },
  )
}

/**
 * Custom hook to fetch a user's public profile using their userId.
 *
 * @param {string} userId - The ID of the user whose profile is to be fetched.
 * @param {boolean} enabled - A flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook, which includes the user's profile data.
 *
 * @throws {Error} - Throws an error if the profile data cannot be fetched.
 */
export function usePublicProfile(userId, enabled) {
  return useQuery(
    ['user', userId],
    async () => {
      if (!userId) return {}
      const { data } = await axios.get(`user/profile/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (data && data.status !== 200)
        throw Error('Unable to fetch Profile data.')
      return data
    },
    { enabled, staleTime: 60000 },
  )
}

/**
 * Custom hook to handle profile image upload.
 *
 * This hook uses `useMutation` from `react-query` to handle the mutation of uploading a profile image.
 * It sends a POST request to the 'user/profile/image' endpoint with the provided data.
 * On success, it triggers an event and invalidates the 'user' and 'me' queries in the query client.
 *
 * @returns {MutationFunction} A mutation function that can be used to trigger the profile image upload.
 *
 * @example
 * const { mutate: uploadProfileImage } = useSetProfileImage();
  
 * uploadProfileImage(formData, {
 *   onSuccess: () => {
 *     console.log('Profile image uploaded successfully');
 *   },
 *   onError: (error) => {
 *     console.error('Error uploading profile image:', error);
 *   },
 * });
 */
export function useSetProfileImage() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('user/profile/image', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Profile-Image-Upload')
        queryClient.invalidateQueries(['user', 'me'])
      },
    },
  )
}

/**
 * Custom hook for handling image uploads using a mutation.
 *
 * @param {string} action - The URL to which the image data will be posted.
 * @param {string} key - The query key to invalidate upon successful upload.
 * @param {Function} [onSuccess] - Optional callback function to be executed on successful upload.
 * @returns {Mutation} - The mutation object for handling the image upload.
 */
export function useImageUpload(action, key, onSuccess?: Function) {
  const queryClient = useQueryClient()

  return useMutation(
    async data => {
      const results = await axios.post(action, data.postData, {
        params: data.params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        if (key) queryClient.invalidateQueries(key)
        if (onSuccess) onSuccess()
      },
    },
  )
}

/**
 * Custom hook to handle image deletion using a mutation.
 *
 * @param {string} action - The URL endpoint for the delete request.
 * @param {string} key - The query key to invalidate upon successful deletion.
 * @param {Function} [onSuccess] - Optional callback function to execute on successful deletion.
 * @returns {Mutation} - The mutation object from react-query.
 */
export function useImageDelete(action, key, onSuccess?: Function) {
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      const results = await axios.delete(action, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        if (key) queryClient.invalidateQueries(key)
        if (onSuccess) onSuccess()
      },
    },
  )
}

/**
 * Custom hook to delete a user's profile image.
 *
 * This hook uses `useMutation` from `react-query` to handle the deletion of the profile image.
 * On successful deletion, it sends an event and invalidates the relevant queries to update the cache.
 *
 * @returns {Mutation} A mutation object from `react-query` to handle the profile image deletion.
 *
 * @example
 * const deleteProfileImageMutation = useDeleteProfileImage();
 * deleteProfileImageMutation.mutate();
 */
export function useDeleteProfileImage() {
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      const results = await axios.delete('user/profile/image', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Profile-Image-Delete')
        queryClient.invalidateQueries(['user', 'me'])
      },
    },
  )
}

/**
 * Custom hook to set user assessment using a mutation.
 *
 * This hook uses `useMutation` from `react-query` to post assessment data to the server.
 * On success, it sends an event and invalidates specific queries in the query client.
 *
 * @returns {Mutation} A mutation object that can be used to trigger the assessment post request.
 */
export function useSetAssessment() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('user/assessment', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Assessment-Set')
        queryClient.invalidateQueries(['user', 'me'])
      },
    },
  )
}

/**
 * Custom hook to set the user's kit using a mutation.
 *
 * This hook uses `useMutation` from `react-query` to post data to the 'user/kit' endpoint.
 * On success, it sends an event 'ATOM-Kit-Set' and invalidates the 'user/me' query.
 *
 * @returns {MutationFunction} A mutation function to set the user's kit.
 */
export function useSetKit() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('user/kit', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Kit-Set')
        queryClient.invalidateQueries(['user', 'me'])
      },
    },
  )
}

/**
 * Custom hook to add a tour response using a POST request.
 
 * This hook uses the `useMutation` hook to send a POST request to the 'user/tour' endpoint
 * with the provided `postData`. The request includes a 'Content-Type' header set to 'application/json'.
 
 * @returns {MutationFunction} A mutation function that can be used to trigger the POST request.
 
 * @throws {Error} Throws an error if the response status is not 200.
 */
export function useAddTourResponse() {
  return useMutation(async postData => {
    const results = await axios.post('user/tour', postData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (results.data.status !== 200) {
      throw new Error(results)
    }
    return results
  }, {})
}

/**
 * Custom error type for form-related errors.
 *
 * @param {string} message - The error message.
 * @param {any} data - Additional data related to the error.
 * @param {string} [code='FormError'] - The error code, defaults to 'FormError'.
 */
export function FormError(message, data, code = 'FormError') {
  this.message = message
  this.code = code
  this.data = data
}

/**
 * Custom hook to submit user training scores.
 *
 * This hook uses `useMutation` from `react-query` to handle the submission of user training scores.
 * It performs an HTTP POST request to submit the data and handles various success and error scenarios.
 *
 * @returns {Mutation} A mutation object from `react-query` that can be used to trigger the score submission.
 *
 * @example
 * const mutation = useSubmitScore();
 * mutation.mutate(postData);
 *
 * @typedef {Object} PostData
 * @property {string} track - The track of the training.
 * @property {string} [trainingId] - The ID of the training, required if track is not 'manual'.
 * @property {string} [id] - The ID of the training to update.
 *
 * @throws {FormError} Throws a FormError if the server responds with a 422 status code.
 * @throws {Error} Throws a generic error if the server responds with a non-200 status code.
 */
export function useSubmitScore() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      // This needs a proper error check
      if (
        !postData?.track ||
        (!postData?.trainingId && postData?.track !== 'manual')
      )
        return {}
      // Use a different endpoint depending on if we are updating or creating a new score
      let url = `${postData.track}/user/training`
      if (postData.id) {
        url = `${postData.track}/user/training/update`
      }
      const results = await axios.post(url, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status === 422) {
        throw new FormError(
          'Error posting user training.',
          results?.data?.value,
        )
      }
      if (results.data.status !== 200) {
        throw Error('Error posting user training.')
      }

      return results
    },
    {
      onSuccess: (response, vars) => {
        sendEvent('ATOM-Training-Submit')
        queryClient.invalidateQueries([
          'leaderboard',
          vars?.track,
          response?.data?.value?.rows[0].date,
        ])
        if (vars?.track === 'workshop')
          queryClient.invalidateQueries(['workshops'])
        if (vars?.track === 'event/challenge') {
          queryClient.invalidateQueries(['event', 'team', 'myTeam'])
        }
        queryClient.invalidateQueries(['training_journal'])
      },
    },
  )
}

/**
 * Custom hook to delete a user training score.
 *
 * This hook uses `useMutation` from `react-query` to handle the deletion of a user training score.
 * It sends a DELETE request to the specified track endpoint with the provided postData.
 *
 * @function useDeleteScore
 * @returns {Mutation} A mutation object from `react-query` to handle the deletion process.
 *
 * @example
 * const deleteScoreMutation = useDeleteScore();
 * deleteScoreMutation.mutate({ id: '123', track: '/api/track' });
 *
 * @throws {FormError} If the server responds with a 422 status code.
 * @throws {Error} If the server responds with a status code other than 200.
 */
export function useDeleteScore() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      // This needs a proper error check
      if (!postData?.id || !postData?.track) return {}
      const results = await axios.delete(`${postData.track}/user/training`, {
        data: { id: postData?.id },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status === 422) {
        throw new FormError(
          'Error deleting user training.',
          results?.data?.value,
        )
      }
      if (results.data.status !== 200) {
        throw Error('Error deleting user training.')
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Training-Delete')
        queryClient.invalidateQueries(['training_journal'])
        queryClient.invalidateQueries(['training'])
        queryClient.invalidateQueries(['dashboard'])
        queryClient.invalidateQueries(['user', 'me'])
      },
    },
  )
}

/**
 * Custom hook to fetch leaderboard data with infinite scrolling.
 *
 * @param {string} leaderboardDate - The date for which to fetch the leaderboard.
 * @param {Object} [filters={}] - Filters to apply to the leaderboard data.
 * @param {boolean} athletes - Flag to indicate if only athletes should be fetched.
 * @param {string} [track] - Optional track identifier.
 * @param {boolean} [enabled=true] - Flag to enable or disable the query.
 * @returns {Object} - The result of the useInfiniteQuery hook.
 */
export function useLeaderboard(
  leaderboardDate: string,
  filters: Object = {},
  athletes: boolean,
  track?: string,
  enabled: boolean = true,
) {
  return useInfiniteQuery(
    ['leaderboard', track || 'atomic', leaderboardDate, filters, athletes],
    async ({ queryKey, pageParam = 1 }) => {
      const [, leaderboardTrack, date, myFilters, featured: athletes] = queryKey

      const featuredAthletes = featured || null

      const params = JSON.parse(
        JSON.stringify({
          date,
          ...myFilters,
          featuredAthletes,
          'per-page': 100,
          page: pageParam,
        }),
      ) // remove any non-value properties

      if (Object.is(parseInt(myFilters?.level, 10), 0)) {
        delete params.level
      }

      if (Object.is(parseInt(myFilters?.division, 10), 0)) {
        delete params.division
      }

      const { data } = await axios.get(`${leaderboardTrack}/leaderboard`, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Leaderboard data.')

      return data
    },
    {
      getNextPageParam: lastPage => {
        if (!lastPage?.value?.pagination?.next) return undefined
        const myurl = new URL(lastPage.value.pagination.next)
        return myurl.searchParams.get('page')
      },
      getPreviousPageParam: firstPage => {
        if (!firstPage?.value?.pagination?.prev) return undefined
        const myurl = new URL(firstPage.value.pagination.prev)
        return myurl.searchParams.get('page')
      },
      keepPreviousData: true,
      staleTime: 60000,
      enabled,
    },
  )
}

/**
 * Custom hook to fetch event team data using a query.
 *
 * @param {string} id - The ID of the event team to fetch.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook.
 */
export function useEventTeam(id, enabled) {
  return useQuery(
    ['event', 'team', id],
    async () => {
      const params = { id }
      const { data } = await axios.get('event/challenge/team/view', {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Event Team data.')

      return data
    },
    {
      enabled,
      staleTime: 60000,
    },
  )
}

/**
 * Custom hook to fetch the user's event team data.
 *
 * @param {boolean} [enabled=true] - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook.
 *
 * @throws {Error} - Throws an error if unable to fetch My Team data.
 */
export function useMyEventTeam(enabled = true) {
  return useQuery(
    ['event', 'team', 'myTeam'],
    async () => {
      const { data } = await axios.get('event/challenge/team/user/myteam', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch My Team data.')

      return data
    },
    {
      enabled,
      staleTime: 60000,
    },
  )
}

/**
 * Custom hook to create or update a team.
 *
 * @param {string} teamId - The ID of the team to update. If not provided, a new team will be created.
 * @returns {Mutation} - A mutation object from react-query's useMutation hook.
 *
 * @example
 * const { mutate, isLoading, error } = useCreateTeam(teamId);
 *
 * mutate(postData, {
 *   onSuccess: (data) => {
 *     console.log('Team created/updated successfully:', data);
 *   },
 *   onError: (error) => {
 *     console.error('Error creating/updating team:', error);
 *   },
 * });
 */
export function useCreateTeam(teamId) {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const url = `event/challenge/team/${teamId ? 'update' : 'create'}`
      const results = await axios.post(url, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status === 422) {
        throw new FormError('Error creating team.', results?.data?.value)
      }
      if (results.data.status !== 200) {
        throw Error('Error creating team.')
      }

      return results
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries(['event', 'team', response.data.value.id])
        queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

/**
 * Custom hook to handle joining a team.
 *
 * This hook uses `useMutation` from `react-query` to handle the mutation of joining a team.
 * It sends a POST request to the `event/challenge/team/user` endpoint with the provided data.
 * On success, it invalidates the relevant queries to ensure the data is up-to-date.
 *
 * @returns {Mutation} A mutation object from `react-query` to handle the join team operation.
 *
 * @throws {FormError} If the response status is 422, indicating a form error.
 * @throws {Error} If the response status is not 200, indicating a general error.
 */
export function useJoinTeam() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post(`event/challenge/team/user`, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status === 422) {
        throw new FormError('Error Joining team.', results?.data?.value)
      }
      if (results.data.status !== 200) {
        throw Error('Error Joining team.')
      }

      return results
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries([
          'event',
          'team',
          response.data?.value?.teamId,
        ])
        queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

/**
 * Custom hook to handle inviting a user to a team.
 *
 * This hook uses `useMutation` from `react-query` to perform a POST request
 * to invite a user to a team. It also invalidates relevant queries upon success.
 *
 * @returns {MutationFunction} A mutation function to be used for inviting a user to a team.
 *
 * @example
 * const { mutate: inviteTeam } = useInviteTeam();
 * inviteTeam(postData);
 */
export function useInviteTeam() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post(
        `event/challenge/team/user/invite`,
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      if (results.data.status !== 200) {
        throw Error('Error Inviting to Team.')
      }

      return results
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries([
          'event',
          'team',
          response.data?.value?.teamId,
        ])
        queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

/**
 * Custom hook to handle the mutation for a user leaving a team.
 *
 * This hook uses `useMutation` from `react-query` to perform an asynchronous
 * operation where a user leaves a team by making a DELETE request to the server.
 * It also handles the invalidation of relevant queries upon a successful mutation.
 *
 * @returns {MutationFunction} A mutation function to trigger the leave team operation.
 *
 * @example
 * const leaveTeamMutation = useLeaveTeam();
 * leaveTeamMutation.mutate({ teamId: 'team123', userId: 'user456' });
 */
export function useLeaveTeam() {
  const queryClient = useQueryClient()

  return useMutation(
    async vars => {
      if (!vars?.teamId || !vars?.userId) return {}
      const results = await axios.delete('event/challenge/team/user', {
        data: {
          teamId: vars.teamId,
          userId: vars.userId,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: (response, vars) => {
        sendEvent('ATOM-User-Left-Team')
        queryClient.invalidateQueries(['event', 'team', vars?.teamId])
        queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

/**
 * Custom hook to delete a team using a mutation.
 *
 * @function
 * @name useDeleteTeam
 * @returns {Mutation} A mutation object to delete a team.
 *
 * @example
 * const mutation = useDeleteTeam();
 * mutation.mutate(teamId);
 *
 * @description
 * This hook uses `useMutation` from `react-query` to delete a team by its ID.
 * It sends a DELETE request to the 'event/challenge/team' endpoint with the team ID in the request body.
 * On successful deletion, it triggers the `onSuccess` callback which sends an event and invalidates related queries.
 *
 * @throws {Error} Throws an error if the response status is not 200.
 */
export function useDeleteTeam() {
  const queryClient = useQueryClient()

  return useMutation(
    async id => {
      if (!id) return {}
      const results = await axios.delete('event/challenge/team', {
        data: {
          id,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: (response, vars) => {
        sendEvent('ATOM-User-Deleat-Team')
        queryClient.invalidateQueries(['event', 'team', vars?.teamId])
        queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

/**
 * Custom hook to search for teams in an event.
 *
 * @param {string} search - The search query string.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook.
 *
 * @throws {Error} - Throws an error if the search request fails.
 */
export function useSearchTeam(search, enabled) {
  return useQuery(
    ['event', 'team', 'search', search],
    async () => {
      const params = { search, 'per-page': 500 }
      const { data } = await axios.get('event/challenge/team', {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to search Event Team data.')

      return data
    },
    {
      staleTime: 60000,
      enabled,
    },
  )
}

/**
 * Custom hook to search for users based on a search query.
 *
 * @param {string} search - The search query to find users.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @returns {Object} - The result of the query, including status and data.
 *
 * @throws {Error} - Throws an error if the search request fails.
 */
export function useSearchUsers(search, enabled) {
  return useQuery(
    ['user', 'search', search],
    async () => {
      const params = { search }
      const { data } = await axios.get('user/search', {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to search Event User data.')

      return data
    },
    {
      staleTime: 60000,
      enabled,
    },
  )
}

/**
 * Custom hook to search for event users.
 *
 * @param {string} search - The search query string.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook.
 *
 * @throws {Error} - Throws an error if the search request fails.
 */
export function useSearchEventUsers(search, enabled) {
  return useQuery(
    ['event', 'user', 'search', search],
    async () => {
      const params = { search }
      const { data } = await axios.get('event/challenge/user', {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to search Event User data.')

      return data
    },
    {
      staleTime: 60000,
      enabled,
    },
  )
}

// Video Library
/**
 * Custom hook to fetch video library data with infinite scrolling support.
 *
 * @param {Object} params - The parameters to be used in the API request.
 * @param {boolean} [enabled=true] - Flag to enable or disable the query.
 * @returns {Object} - The result of the useInfiniteQuery hook.
 *
 * @example
 * const { data, fetchNextPage, hasNextPage } = useVideoLibrary({ search: 'example' });
 *
 * @function
 */
export function useVideoLibrary(params, enabled = true) {
  /**
   * Flattens the `params` object by converting array values to comma-separated strings.
   * Adds a default `track` property with the value 'affiliate'.
   *
   * @returns {Object} The flattened parameters object.
   */
  const flattenParams = () => {
    const flattenedParams = { track: 'affiliate' }
    const paramKeys = Object.keys(params)
    for (let k = 0; k < paramKeys.length; k += 1) {
      const paramKey = paramKeys[k]
      const param = params[paramKey]
      if (Array.isArray(param)) {
        if (param.length > 0) flattenedParams[paramKey] = param.join(',')
      } else {
        flattenedParams[paramKey] = param
      }
    }
    return flattenedParams
  }

  return useInfiniteQuery(
    ['media', params],
    async ({ pageParam = 1 }) => {
      const flattenedParams = flattenParams()
      const myParams = { ...flattenedParams, page: pageParam }
      const { data } = await axios.get('media', {
        params: myParams,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to search Video Library.')

      return data
    },
    {
      getNextPageParam: lastPage => {
        if (!lastPage?.value?.pagination?.next) return undefined
        const myurl = new URL(lastPage.value.pagination.next)
        return myurl.searchParams.get('page')
      },
      getPreviousPageParam: firstPage => {
        if (!firstPage?.value?.pagination?.prev) return undefined
        const myurl = new URL(firstPage.value.pagination.prev)
        return myurl.searchParams.get('page')
      },
      keepPreviousData: true,
      staleTime: 60000 * 60, // one hour cache
      enabled,
    },
  )
}

/**
 * Custom hook to fetch and manage dashboard data using React Query.
 *
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook.
 *
 * The hook fetches data from the 'dashboard' endpoint and updates the query cache
 * with 'promotions' and 'affiliate' data if available.
 *
 * @throws {Error} - Throws an error if the data status is not 200.
 */
export function useDashboard(enabled) {
  const queryClient = useQueryClient()

  return useQuery(
    ['dashboard'],
    async () => {
      const { data } = await axios.get(`dashboard`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Training data.')

      const templateResponse = {
        value: {},
        status: 200,
        success: true,
        source: 'dashboard',
      }

      if (data?.value?.promotions) {
        queryClient.setQueryData(['promotions'], {
          ...templateResponse,
          value: data?.value?.promotions,
        })
      }

      if (data?.value?.affiliate) {
        queryClient.setQueryData(['affiliate', 'myAffiliate'], {
          ...templateResponse,
          value: data?.value?.affiliate,
        })
      }

      return data
    },
    {
      enabled,
      keepPreviousData: true,
      staleTime: 60000,
    },
  )
}

/**
 * Custom hook to fetch workshop data using React Query.
 *
 * @param {boolean} [enabled=true] - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook.
 *
 * @throws {Error} - Throws an error if the workshop data cannot be loaded.
 */
export function useWorkshops(enabled = true) {
  return useQuery(
    ['workshops'],
    async () => {
      const { data } = await axios.get('workshop', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to load Workshop data.')

      return data
    },
    {
      staleTime: 60000,
      enabled,
    },
  )
}

/**
 * Custom hook to fetch workshop training data.
 *
 * @param {string} day - The day of the training.
 * @param {string} id - The ID of the workshop.
 * @param {boolean} [enabled=true] - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook.
 */
export function useWorkshopTraining(day, id, enabled = true) {
  return useQuery(
    ['workshop', 'training', id, day],
    async ({ queryKey }) => {
      // const json = localStorage.getItem('config')
      // const savedConfig = JSON.parse(json)

      const [, , workshopId, trainingDay] = queryKey

      const params = {
        day: trainingDay,
        workshopId,
      }

      const { data } = await axios.get(`workshop/training/view`, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Training data.')

      return data
    },
    {
      enabled,
      keepPreviousData: true,
      staleTime: 60000,
    },
  )
}

/**
 * Custom hook to fetch workshop training journal data using React Query.
 *
 * @param {string} day - The specific day for which to fetch the training journal.
 * @param {string} workshopId - The ID of the workshop.
 * @param {number} page - The current page number for pagination.
 * @param {number} perPage - The number of items per page.
 * @param {string} sort - The sorting criteria.
 * @param {string} training - The training type.
 * @param {string} track - The track type, defaults to 'workshop' if not provided.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook, including the data, error, and status.
 */
export function useWorkshopTrainingJournal(
  day,
  workshopId,
  page,
  perPage,
  sort,
  training,
  track,
  enabled,
) {
  return useQuery(
    [
      'training_journal',
      track || 'workshop',
      day,
      workshopId,
      page,
      perPage,
      sort,
      training,
    ],
    async ({ queryKey }) => {
      const [
        ,
        trainingTrack,
        apiDay,
        apiWorkshopId,
        apiPage,
        apiPerPage,
        apiSort,
        apiTraining,
      ] = queryKey

      const options = {
        day: apiDay,
        workshopId: apiWorkshopId,
        page: apiPage,
        'per-page': apiPerPage,
        sort: apiSort,
        training: apiTraining,
      }

      const params = Object.keys(options).reduce((lastObj, key) => {
        const value = options[key]
        const newObj = { ...lastObj }

        if (value !== null) {
          newObj[key] = value
        }

        return newObj
      }, {})

      const url = `${trainingTrack}/user/training`

      const { data } = await axios.get(url, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Workshop Training data.')

      return data
    },
    {
      enabled,
      staleTime: 60000,
      keepPreviousData: true,
    },
  )
}

/**
 * Custom hook to fetch pages data using a query key.
 *
 * @param {string} key - The key to identify the query.
 * @param {boolean} [enabled=true] - Flag to enable or disable the query.
 * @returns {object} - The result of the useQuery hook.
 *
 * @throws {Error} If the data status is not 200.
 */
export function usePages(key, enabled = true) {
  return useQuery(
    ['pages', key],
    async () => {
      const params = {
        key,
      }
      const { data } = await axios.get('pages', {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200) throw Error('Unable to load Page data.')

      return data
    },
    {
      staleTime: 60000,
      enabled,
    },
  )
}

export default AtomProvider
