import React, { useState } from 'react'
import AssessmentQuestion from './AssessmentQuestion'

type Props = {
  onNext: Object,
  nextTab: string,
  prevTab: string,
  setActiveTab: Object,
  updateResponse: Object,
  responses: Object,
  setBackgroundImage: Object,
  questions: Object,
  hideOnboardingSkip?: Boolean,
  onExitAssessment?: Objejct,
  startingQuestion?: string,
}

Assessment.defaultProps = {
  hideOnboardingSkip: false,
  onExitAssessment: undefined,
  startingQuestion: undefined,
}

/**
 * Assessment component handles the flow of questions in an onboarding process.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {Function} props.onNext - Callback function to be called when moving to the next section.
 * @param {Function} props.onExitAssessment - Callback function to be called when exiting the assessment.
 * @param {string} props.nextTab - The identifier for the next tab to navigate to.
 * @param {string} props.prevTab - The identifier for the previous tab to navigate to.
 * @param {Function} props.setActiveTab - Function to set the active tab.
 * @param {Array} props.questions - Array of question objects for the assessment.
 * @param {Function} props.setBackgroundImage - Function to set the background image.
 * @param {Function} props.updateResponse - Function to update the response for a question.
 * @param {Object} props.responses - Object containing the responses for the questions.
 * @param {boolean} props.hideOnboardingSkip - Flag to hide the onboarding skip option.
 * @param {number} [props.startingQuestion=0] - The index of the starting question.
 *
 * @returns {JSX.Element} The rendered Assessment component.
 */
function Assessment(props: Props) {
  const {
    onNext,
    onExitAssessment,
    nextTab,
    prevTab,
    setActiveTab,
    questions,
    setBackgroundImage,
    updateResponse,
    responses,
    hideOnboardingSkip,
    startingQuestion,
  } = props

  const [curQuestion, setCurQuestion] = useState(startingQuestion || 0)
  const [questionStack, setQuestionStack] = useState([])

  /**
   * Handles the transition to the next question or tab in the assessment process.
   *
   * @param {string} id - The identifier of the current question.
   * @param {any} value - The response value for the current question.
   * @param {string} [skipTo] - Optional identifier of the question to skip to.
   *
   * @returns {void}
   */
  const handleNext = (id, value, skipTo = undefined) => {
    // if we have a skip that isn't an index we will get -1 and thus skip to the next section
    const updatedValue = { ...responses, [id]: value }

    updateResponse(updatedValue)

    let nextQuestion =
      skipTo === undefined
        ? curQuestion + 1
        : questions.findIndex(question => question.id === skipTo)

    if (nextQuestion > questions.length - 1 || nextQuestion < 0) {
      if (nextTab) {
        setActiveTab(nextTab)
      }

      if (onNext) {
        onNext(updatedValue)
      }
    } else {
      const question = questions[nextQuestion]

      if (question.skipIf && question.skipIf.length) {
        let skipThisQuestion = false

        question.skipIf.forEach(skip => {
          if (
            responses[skip.questionId] &&
            responses[skip.questionId].includes(skip.answerId)
          ) {
            skipThisQuestion = true
          }
          nextQuestion = skipThisQuestion ? nextQuestion + 1 : nextQuestion
        })
      }

      setQuestionStack([curQuestion, ...questionStack])
      setCurQuestion(nextQuestion || nextQuestion)
    }
  }

  /**
   * Handles the back navigation logic for the assessment.
    
   * If the question stack is empty, it will either set the active tab to the previous tab
   * or call the onExitAssessment function if provided.
   * Otherwise, it will set the current question to the last question in the stack
   * and update the question stack by removing the last question.
    
   * @function handleBack
   */
  const handleBack = () => {
    const [lastQuestion, ...rest] = questionStack

    if (questionStack.length === 0) {
      if (prevTab) {
        setActiveTab(prevTab)
      }
      if (onExitAssessment) {
        onExitAssessment()
      }
    } else {
      setCurQuestion(lastQuestion)
      setQuestionStack(rest)
    }
  }

  /**
   * Retrieves the response for a given question index.
   *
   * @param {number} questionIndex - The index of the question to retrieve the response for.
   * @returns {(any|undefined)} The response for the given question index, or undefined if the question index or response is invalid.
   */
  const currentResponse = questionIndex => {
    if (
      questionIndex !== undefined &&
      questions[questionIndex] !== undefined &&
      responses[questions[questionIndex].id] !== false
    ) {
      return responses[questions[questionIndex].id]
    }

    return undefined
  }

  return (
    <div className="assessment">
      <AssessmentQuestion
        key={questions[curQuestion].id}
        question={questions[curQuestion]}
        currentResponse={currentResponse(curQuestion)}
        setBackgroundImage={setBackgroundImage}
        onBack={handleBack}
        onNext={handleNext}
        updateResponse={updateResponse}
        hideOnboardingSkip={hideOnboardingSkip}
      />
    </div>
  )
}

export default Assessment
